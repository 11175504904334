import { memo } from "react"
import { styled } from "@mui/material"
import { useOeInformationItemState } from "../ContextProviderStates/OeInformationItemState"
import { Typography } from "../../../generics/typographie"
import { Prices } from "./Prices"

const StyledPrice = styled(Typography)({
    textAlign: "right",
    variant: "body2",
    fontWeight: 500,
    fontSize: 15,
    whiteSpace: "nowrap",
    lineHeight: 1,
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "1em",
})

export const OePrices = memo(() => {
    const oePrices = useOeInformationItemState((x) => x.oePrices)
    const articleErpInfo = useOeInformationItemState((x) => x.articleErpInfos)?.default

    if (articleErpInfo?.state === "success" && articleErpInfo.response.prices?.length) {
        return <Prices prices={articleErpInfo.response.prices} />
    }

    if (!oePrices?.length) {
        return null
    }

    return (
        <>
            {oePrices.map((price, idx) => (
                <StyledPrice key={`${price.price + idx}`}>{price.price}</StyledPrice>
            ))}
        </>
    )
})
