import { memo } from "react"
import { AddToBasket } from "./AddToBasket"
import { useArticleItemState } from "../ContextProviderStates"

export const AddOeToBasket = memo(() => {
    const quantity = useArticleItemState((x) => x.quantity)
    const basketQuantity = useArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleChangeQuantity = useArticleItemState((x) => x.handleChangeQuantity)
    const handleRemoveFromBasket = useArticleItemState((x) => x.handleRemoveFromBasket)
    const handleAddToBasket = useArticleItemState((x) => x.handleAddToBasket)

    return (
        <AddToBasket
            onAddToBasket={handleAddToBasket}
            handleChangeQuantity={handleChangeQuantity}
            onRemoveFromBasket={handleRemoveFromBasket}
            originalQuantity={quantity}
            basketQuantity={basketQuantity}
            division={1}
        />
    )
})
