import { RegistrationNoType } from ".."
import { VehicleType } from "../enumerations"

export type ShowConfigResponse = {
    defaultVehicleLookup?: VehicleLookupConfig
    configuredVehicleLookups: VehicleLookupConfig[]
}

export type VehicleLookupConfig = {
    id: number
    description: string

    lookupTypeId: RegistrationNoType
    queryTypeId: QueryType

    isDefault: boolean
    isUsedInDefaultSearch: boolean
    isSelectable: boolean
    supportedVehicleTypes: VehicleType[]

    billingModuleId?: BillingModuleId

    countryCode?: string

    quota?: Quota
}

export enum QueryType {
    Other = 0,
    PlateId = 1,
    Vin = 2,
    PlateIdOrVin = 3,
}

export enum BillingModuleId {
    VinQuery = 18,
    Flatrate = 22,
    ItemizedBilling = 108,
    ItemizedBillingTruck = 208
}

export type Quota = {
    hasQuotaAvailable?: boolean,
    limit?: number,
    used?: number
}
