import { channel, UserSettingsDefaults, UserContext } from "@tm/models"
import { bindSpecialReactMethods, convertBase64ImageToByteArray, Dictionary } from "@tm/utils"
import { Children, Component } from "react"
import { BehaviorSubject, Subscription } from "rxjs"

import * as Data from "../data"
import { ChangeOrderOptionsRequest, DirectBuyOptions, HourlyRate, RepairTimeOptions, UpdateRepairShopRequest, User, UserSettings, UserSettingsKeys } from "../model"
import { UserProviderContext } from "../model/UserProviderContext"

type CreateUserProviderOptions = {
    authorityServiceUrl: string
    repairShopServiceUrl: string
    externalAuthentication?: Record<string, string>
    userContext?: UserContext
    tokenHandler?: Record<string, string>
    userSettingsDefaults: UserSettingsDefaults
}

export async function createUserProvider(options: CreateUserProviderOptions) {
    const {authorityServiceUrl, repairShopServiceUrl, externalAuthentication, userSettingsDefaults } = options
    const _userContext = window.userContext = options.userContext || (await Data.getUserContext(authorityServiceUrl))
    const _contextSubject = new BehaviorSubject(_userContext)
    const _settingsSubject = new BehaviorSubject<UserSettings | undefined>(undefined)

    return class UserProvider extends Component {
        _contextSubscription: Subscription | undefined = undefined
        _settingsSubscription: Subscription | undefined = undefined

        constructor(props: any) {
            super(props)
            bindSpecialReactMethods(this)
        }

        componentDidMount() {
            this._contextSubscription = _contextSubject.subscribe(() => {
                this.forceUpdate()
            })
            this._settingsSubscription = _settingsSubject.subscribe(() => {
                this.forceUpdate()
            })

            this.getUserSettings()
            this.addExternalTokensToUserContext()
        }

        componentWillUnmount() {
            if (this._contextSubscription && !this._contextSubscription.closed) {
                this._contextSubscription.unsubscribe()
            }
            if (this._settingsSubscription && !this._settingsSubscription.closed) {
                this._settingsSubscription.unsubscribe()
            }
        }

        addExternalTokensToUserContext() {
            Data.getExternalAuthenticationTokens(externalAuthentication ?? {}).then(response => {
                // Only update the userContext if there actually are any tokens
                if (Object.keys(response).length) {
                    const externalAuthenticationTokens = {..._userContext.externalAuthenticationTokens}
                    Object.entries(response).forEach(([key,value]) => {
                        const tokenHandler = options.tokenHandler?.[key] ?? key
                        sessionStorage.setItem(`token://${tokenHandler}`, JSON.stringify(value))
                        externalAuthenticationTokens[tokenHandler] = value.token
                    })
                    _contextSubject.next({
                        ..._userContext,
                        externalAuthenticationTokens,
                    })
                }

                channel("GLOBAL").publish("USER/CONTEXT_LOADED", { context: _userContext })
            })
        }

        getUserSettings = (): Promise<UserSettings> => {
            return Promise.all([
                Data.showAllOptions(repairShopServiceUrl, {
                    includeRepairShop: true,
                    includeHourlyRates: true,
                    includeOrderOptions: true,
                    includeRepairTimeOptions: true,
                    includeDirectBuyOptions: true,
                }),
                Data.getUserSetting("SHOW_PURCHASE_PRICE"),
                Data.getUserSetting("ARTICLE_LIST_SETTINGS"),
                Data.getUserSetting("ACTIVE_VEHICLE_DATA_PROVIDERS"),
                Data.getUserSetting("DMS_SETTINGS"),
                Data.getUserSetting("AUTO_EXPAND_FILTERS"),
                Data.getUserSetting("ORDER_OPTIONS_EXPANDED"),
                Data.getUserSetting("EAN_NUMBER_SEARCH_ENABLED"),
                Data.getUserSetting("BASKET_MEMO_OPTIONS"),
                Data.getUserSetting("BASKET_ITEM_SORTING"),
                Data.getUserSetting("SHOW_PURCHASE_PRICE_IN_SUMMARY"),
                Data.getUserSetting("VRC_SCAN_OPTIONS"),
                Data.getUserSetting("HIDE_WHEELS_AVAILABILITY"),
                Data.getUserSetting("SINDRI_SETTINGS"),
                Data.getUserSetting("ARTICLE_LIST_DEFAULT_SORTING"),
                Data.getUserSetting("DRIVEMOTIVE_SETTINGS"),
                Data.getUserSetting("PARTSLIFE_SETTINGS")
            ]).then(responses => {

                const userSettings: UserSettings = {
                    repairShop: responses[0].repairShop,
                    hourlyRates: mapHourlyRates(responses[0].hourlyRates?.hourlyRates),
                    hourlyRatesCurrencyCode: responses[0].hourlyRates?.currencyCode || "EUR",
                    orderOptions: {
                        repairShopResponse: responses[0].orderOptions,
                        expandedByDefault: responses[6],
                        basketMemoContext: responses[8]?.context,
                        basketMemoSections: responses[8]?.sections,
                    },
                    repairTimeOptions: responses[0].repairTimeOptions,
                    directBuyOptions: {
                        repairShopResponse: responses[0].directBuyOptions,
                        eanNumberSearchEnabled: responses[7],
                    },
                    showPurchasePrice: getShowPurchasePriceFromResponse(responses[1], _contextSubject.getValue()),
                    showPurchasePriceInSummary: responses[10], // No default here because we have to know if the setting is not yet set for a user!
                    articleListSettings: responses[2] || undefined,
                    activeVehicleDataProviders: responses[3] || {},
                    dmsSettings: responses[4] || {},
                    autoExpandFilters: responses[5],
                    itemSorting: responses[9] || false,
                    vrcScanOptions: {
                        customerEnabled: responses[11]?.customerEnabled,
                        vehicleEnabled: responses[11]?.vehicleEnabled
                    },
                    hideWheelsAvailability: responses[12] ?? userSettingsDefaults?.hideWheelsAvailability,
                    sindriSettings: responses[13] || {},
                    articleListDefaultSorting: responses[14],
                    drivemotiveSettings: responses[15] || {},
                    partsLifeSettings: responses[16] || {}
                    // IMPORTANT: When adding or changing a setting here also change the according "case" in the "switch" of "setSetting" below!
                }

                _settingsSubject.next(userSettings)
                return userSettings
            })
        }

        setSetting = async (key: UserSettingsKeys, value: any): Promise<UserSettings> => {
            await Data.setUserSetting(key, value)

            const response = await Data.getUserSetting(key)
            let userSettings = _settingsSubject.getValue()

            if (!userSettings) {
                return this.getUserSettings()
            }

            userSettings = { ...userSettings }

            switch (key) {
                case "HIDE_WHEELS_AVAILABILITY":
                    userSettings.hideWheelsAvailability = response
                    break
                case "SHOW_PURCHASE_PRICE":
                    userSettings.showPurchasePrice = getShowPurchasePriceFromResponse(response, _contextSubject.getValue())
                    break
                case "SHOW_PURCHASE_PRICE_IN_SUMMARY":
                    userSettings.showPurchasePriceInSummary = response
                    break
                case "AUTO_EXPAND_FILTERS":
                    userSettings.autoExpandFilters = response
                    break
                case "ARTICLE_LIST_SETTINGS":
                    userSettings.articleListSettings = response || undefined
                    break
                case "ACTIVE_VEHICLE_DATA_PROVIDERS":
                    userSettings.activeVehicleDataProviders = response || {}
                    break
                case "DMS_SETTINGS":
                    userSettings.dmsSettings = response || {}
                    break
                case "ORDER_OPTIONS_EXPANDED":
                    userSettings.orderOptions = {
                        ...userSettings.orderOptions,
                        expandedByDefault: response
                    }
                    break
                case "EAN_NUMBER_SEARCH_ENABLED":
                    userSettings.directBuyOptions = {
                        ...userSettings.directBuyOptions,
                        eanNumberSearchEnabled: response
                    }
                    break
                case "BASKET_MEMO_OPTIONS":
                    userSettings.orderOptions = {
                        ...userSettings.orderOptions,
                        basketMemoContext: response?.context,
                        basketMemoSections: response?.sections
                    }
                    break
                case "VRC_SCAN_OPTIONS":
                    userSettings.vrcScanOptions = {
                        customerEnabled: response?.customerEnabled,
                        vehicleEnabled: response?.vehicleEnabled
                    }
                    break
                case "BASKET_ITEM_SORTING": {
                    userSettings.itemSorting = response || false
                    break
                }
                case "SINDRI_SETTINGS": {
                    userSettings.sindriSettings = response || {}
                    break
                }
                case "ARTICLE_LIST_DEFAULT_SORTING": {
                    userSettings.articleListDefaultSorting = response
                    break
                }
                case "DRIVEMOTIVE_SETTINGS": {
                    userSettings.drivemotiveSettings = response || {}
                    break
                }
                case "PARTSLIFE_SETTINGS": {
                    userSettings.partsLifeSettings = response || { hasPartsLifeActive: false}
                    break
                }
            }

            _settingsSubject.next(userSettings)

            return userSettings
        }

        changeLogo = async (logoData: string): Promise<UserSettings> => {
            const logoBytes = convertBase64ImageToByteArray(logoData)
            await Data.changeLogo(repairShopServiceUrl, logoBytes)
            return this.updateLogoState(logoData)
        }

        changePrintLogo = async (printLogo: boolean): Promise<UserSettings> => {
            await Data.changePrintLogo(repairShopServiceUrl, printLogo)

            let userSettings = _settingsSubject.getValue()

            if(!userSettings?.repairShop){
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                repairShop: { ...userSettings.repairShop, printLogo}
            }

            _settingsSubject.next(userSettings)
            return userSettings
        }

        removeLogo = async (): Promise<UserSettings> => {
            await Data.removeLogo(repairShopServiceUrl)
            return this.updateLogoState(undefined)
        }

        updateLogoState = async (logo: string | undefined): Promise<UserSettings> => {
            let userSettings = _settingsSubject.getValue()

            if (!userSettings?.repairShop) {
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                repairShop: { ...userSettings.repairShop, logo }
            }

            _settingsSubject.next(userSettings)
            return userSettings
        }

        changeHourlyRates = async (hourlyRates: Array<HourlyRate>, currencyCode: string): Promise<UserSettings> => {
            await Data.changeHourlyRates(repairShopServiceUrl, hourlyRates, currencyCode)
            return this.updateUserSettingsState({ hourlyRates, hourlyRatesCurrencyCode: currencyCode })
        }

        changeDirectBuyOptions = async (directBuyOptions: DirectBuyOptions): Promise<UserSettings> => {
            const response = await Data.changeDirectBuyOptions(repairShopServiceUrl, directBuyOptions)

            return this.updateUserSettingsState(userSettings => ({
                directBuyOptions: {
                    ...userSettings.directBuyOptions,
                    repairShopResponse: response
                }
            }))
        }

        changeOrderOptions = async (orderOptions: ChangeOrderOptionsRequest): Promise<UserSettings> => {
            await Data.changeOrderOptions(repairShopServiceUrl, orderOptions)
            const response = await Data.showOrderOptions(repairShopServiceUrl)

            return this.updateUserSettingsState(userSettings => ({
                orderOptions: {
                    ...userSettings.orderOptions,
                    repairShopResponse: response
                }
            }))
        }

        updateRepairShop = async (data: UpdateRepairShopRequest): Promise<UserSettings> => {
            const response = await Data.updateRepairShop(repairShopServiceUrl, data)
            return this.updateUserSettingsState({ repairShop: response })
        }

        changeRepairTimeOptions = async (options: RepairTimeOptions): Promise<UserSettings> => {
            const response = await Data.changeRepairTimeOptions(repairShopServiceUrl, options)
            return this.updateUserSettingsState({ repairTimeOptions: response })
        }

        updateUserSettingsState = async (
            updatedValuesOrUpdater: Partial<UserSettings> | ((settings: UserSettings) => Partial<UserSettings>)
        ): Promise<UserSettings> => {
            let userSettings = _settingsSubject.getValue()

            if (!userSettings) {
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                ...(typeof updatedValuesOrUpdater === "function" ? updatedValuesOrUpdater(userSettings) : updatedValuesOrUpdater)
            }

            _settingsSubject.next(userSettings)
            return userSettings
        }

        getContext(): User {
            return {
                userSettings: _settingsSubject.getValue(),
                userContext: _contextSubject.getValue(),
                reloadUserSettings: this.getUserSettings,
                setUserSetting: this.setSetting,
                changeLogo: this.changeLogo,
                changePrintLogo: this.changePrintLogo,
                removeLogo: this.removeLogo,
                changeHourlyRates: this.changeHourlyRates,
                updateRepairShop: this.updateRepairShop,
                changeRepairTimeOptions: this.changeRepairTimeOptions,
                changeOrderOptions: this.changeOrderOptions,
                changeDirectBuyOptions: this.changeDirectBuyOptions,
            }
        }

        render() {
            const { children } = this.props
            return (
                <UserProviderContext.Provider value={this.getContext()}>
                    {children ? Children.only(children) : null}
                </UserProviderContext.Provider>
            );
        }
    };
}

function getShowPurchasePriceFromResponse(response: any, userContext: UserContext) {
    if (userContext.parameter.purchasePricesDisabled || response === false) {
        return false
    }

    return true
}

function mapHourlyRates(rates: Array<HourlyRate> | undefined): Array<HourlyRate> | undefined {
    if (!rates) {
        return rates
    }

    return rates.map(x => {
        return {
            ...x,
            hourlyRate: x.hourlyRate ?? 0,
        }
    })
}
