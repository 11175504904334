import { memo } from "react"
import { Box, Fade, Grid, Stack, styled } from "@mui/material"
import { AdditionalInformations } from "../AdditionalInformations"
import { RightActions } from "../Actions/RightActions"
import { ArticleNumbers } from "../ArticleNumbers"
import { Attributes } from "../Attributes"
import { Descriptions } from "../Descriptions"
import { DefaultArticleErpContainer } from "../ErpContainer"
import { CenterActions } from "../Actions/CenterActions"
import { Image } from "../Image"
import { Notifications } from "../Notifcations"
import { References } from "../References"
import { Supplier } from "../Supplier"
import { Transactions } from "../Transactions"
import { Notes } from "../Notes"
import { TraderSelection } from "../TraderSelection"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { BonusSystem } from "../BonusSystem"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"
import { Divider } from "../../../generics/divider"
import { Button } from "../../../generics/button"

export type DefaultArticleItemProps = {
    disabled?: boolean
}

const ArticleWrapper = styled(Box)({
    marginBottom: 8,
})

const ArticleContainer = styled(Box, { shouldForwardProp: (prop: string) => !["disabled"].includes(prop) })<DefaultArticleItemProps>(
    ({ theme, disabled }) => ({
        margin: "8px 0 0 0",
        backgroundColor: "#fafafa",
        boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
        width: "100%",
        borderBottom: "3px #c3c3c3 solid",

        ...(disabled && {
            marginTop: 0,
            pointerEvents: "none",
            opacity: theme.opacity?.disabled ?? 0.3,
        }),
    })
)

const GridItem = styled(Grid)({
    position: "relative",
    paddingRight: "12px",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
})

const DefaultArticleItemComponent = memo(({ disabled }: DefaultArticleItemProps) => {
    const expanded = useDefaultArticleItemState((x) => x.expanded)
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)

    return (
        <ArticleWrapper>
            <ArticleContainer disabled={disabled}>
                <Grid container direction="row" wrap="nowrap" alignItems="start">
                    {/* Expand Icon */}
                    <Grid item alignItems="center">
                        <Button
                            sx={{ marginTop: "14px" }}
                            variant="text"
                            startIcon={expanded ? <Icon name="up" /> : <Icon name="down" />}
                            onClick={toggleExpand}
                        />
                    </Grid>

                    {/* Article Image, Supplier */}
                    <GridItem item flexBasis="90px" overflow="hidden">
                        <Stack spacing={1} alignItems="flex-start">
                            <Image />
                            <Supplier />
                            <BonusSystem />
                        </Stack>
                    </GridItem>

                    {/* Article Infos */}
                    <GridItem item xs p={1} overflow="hidden">
                        <Stack spacing={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Stack>
                                    <ArticleNumbers />
                                    <Descriptions />
                                </Stack>
                                <CenterActions />
                            </Stack>

                            <Box>
                                <Attributes />

                                <Fade in={expanded} unmountOnExit mountOnEnter appear={false}>
                                    <Box>
                                        <References />
                                        <AdditionalInformations />
                                        <Notifications />
                                    </Box>
                                </Fade>
                            </Box>
                        </Stack>
                    </GridItem>

                    {/* Actions, ERP Infos */}
                    <Grid item flex="0 0 270px" justifyContent="end" paddingX={1.5} paddingY={1}>
                        <Stack spacing={2}>
                            <RightActions />
                            <Transactions />
                            <DefaultArticleErpContainer />
                        </Stack>
                    </Grid>
                </Grid>
            </ArticleContainer>
            <Notes />
            <TraderSelection />
        </ArticleWrapper>
    )
})

export const DefaultArticleItem = memo(() => {
    const disableReason = useDefaultArticleItemState((x) => x.options.disableReason)

    if (!disableReason) {
        return <DefaultArticleItemComponent />
    }

    return (
        <Box>
            <Stack direction="row" gap={1} alignItems="center" mt={1}>
                <Icon name="not" color="error" />
                <Typography>{disableReason}</Typography>
                <Box flex={1}>
                    <Divider light />
                </Box>
            </Stack>
            <DefaultArticleItemComponent disabled />
        </Box>
    )
})
