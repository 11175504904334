import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, ClickAwayListener, Fade, LinearProgress, Popper, Stack } from "@mui/material"
import { IconButton as ArticleIconButton } from "../IconButton"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { Paper } from "../../../generics/paper"
import { Typography } from "../../../generics/typographie"

export function FoundBy() {
    const article = useDefaultArticleItemState((x) => x.article)

    const { translateText } = useLocalization()
    const [showPopper, setShowPopper] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const articleFoundBy = useDefaultArticleItemState((x) => x.foundBy)

    const foundByTexts = useMemo(
        () =>
            articleFoundBy?.hitInfos?.map(({ searchFilter, referenceNo, manufacturerName }) =>
                [searchFilter, referenceNo, manufacturerName].join(" ")
            ) ?? [],
        [articleFoundBy?.hitInfos]
    )

    const hidePopper = useCallback(() => {
        setShowPopper(false)
    }, [])

    useEffect(() => {
        window.addEventListener("wheel", hidePopper)
        return () => window.removeEventListener("wheel", hidePopper)
    }, [])

    const handleClick = useCallback(() => {
        if (!articleFoundBy) {
            return
        }
        setShowPopper((state) => !state)
        if (!articleFoundBy.hitInfos) {
            articleFoundBy.load()
        }
    }, [articleFoundBy])

    if (!articleFoundBy) {
        return null
    }

    return (
        <ClickAwayListener onClickAway={() => setShowPopper(false)}>
            <Box>
                <Tooltip title={showPopper ? "" : translateText(1387)}>
                    <ArticleIconButton onClick={handleClick} ref={buttonRef}>
                        <Icon name="articlesearch" />
                    </ArticleIconButton>
                </Tooltip>

                <Popper open={showPopper} anchorEl={buttonRef.current} placement="bottom" transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ px: 1, py: 0.5, minWidth: "200px", minHeight: "45px" }} elevation={4}>
                                <Typography variant="label">{translateText(1388)}</Typography>
                                {articleFoundBy.isLoading && <LinearProgress />}
                                <Stack gap={0.5}>
                                    {foundByTexts.map((text) => (
                                        <Box key={text}>{text}</Box>
                                    ))}
                                </Stack>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    )
}
