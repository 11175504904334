import { ArticleAction } from "@tm/models"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"

type ButtonActionProps = {
    action: ArticleAction
    hideText?: boolean
}

export function ButtonAction({ action: { tooltip, text, handler, icon, isHighlight }, hideText }: ButtonActionProps) {
    let content = (
        <Button
            onClick={handler}
            startIcon={<Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            size="small"
            variant="outlined"
            color={isHighlight ? "primary" : undefined}
        >
            {!hideText && text}
        </Button>
    )

    if (tooltip) {
        content = <Tooltip title={tooltip}>{content}</Tooltip>
    }

    return content
}
