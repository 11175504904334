import { useCallback, useEffect, useMemo } from "react"
import { useHistory, useRouteMatch } from "react-router"
import { isEqual } from "lodash"
import { useSetRecoilState } from "recoil"
import { ModuleTab, channel } from "@tm/models"
import { Overwrite, encodeUniqueId } from "@tm/utils"
import { tabsSelector, visibleTabCountSelector } from "./state"
import { isModalUrl, isSameTab } from "."
import { useWorkTaskId } from "../worktask"

export function useModuleNavigation() {
    const history = useHistory()
    const { params } = useRouteMatch<{ workTaskId?: string }>()

    const workTaskId = useWorkTaskId()
    const encodedWorktaskId = useMemo(() => (workTaskId ? encodeUniqueId(workTaskId) : undefined), [workTaskId])

    const setVisibleTabCount = useSetRecoilState(visibleTabCountSelector(workTaskId))
    const setTabs = useSetRecoilState(tabsSelector(workTaskId))

    const resetVisibleTabCount = useCallback(() => setVisibleTabCount(-1), [setVisibleTabCount])

    const closeTabs = useCallback(
        (urls: string[]) => {
            setTabs((prev) => prev.filter((x) => !urls.includes(x.url)))
            const currentUrl = `${location.pathname}${location.search}`
            if (urls.includes(currentUrl)) {
                history.push(`/${encodedWorktaskId}`)
            }
            resetVisibleTabCount()
        },
        [encodedWorktaskId, history, setTabs]
    )

    const closeTab = useCallback(
        (url: string) => {
            closeTabs([url])
        },
        [closeTabs]
    )

    useEffect(() =>{
        return channel("WORKTASK", workTaskId).subscribe("MODULE/OPENED", showTab)
    }, [])

    const showTab = useCallback(
        (moduleTab?: Overwrite<ModuleTab, { url?: string }>) => {
            if (!moduleTab) {
                setTabs((prev) => {
                    let currentTabs = prev
                    const selectedIndex = currentTabs.findIndex((x) => x.isSelected)
                    if (selectedIndex !== -1) {
                        currentTabs = currentTabs.with(selectedIndex, { ...currentTabs[selectedIndex], isSelected: false })
                    }
                    return currentTabs
                })
            }

            if (params.workTaskId !== encodedWorktaskId || !moduleTab?.title) {
                return
            }
            
            // extra check if the workTaskId in the url is really the correct one
            if (location.pathname.replace(/^\//, "").split("/")[0] !== encodedWorktaskId) {
                return
            }

            resetVisibleTabCount()

            const currentUrl = `${location.pathname}${location.search}`
            let currentTab = moduleTab

            if (!currentTab.preventURLRewrite) {
                currentTab = { ...moduleTab, url: currentUrl }
            }

            setTabs((prev) => {
                let currentTabs = prev
                let pos = currentTabs.findIndex((x) => isSameTab(x, currentTab as ModuleTab))

                if (pos === -1) {
                    // Tab does not exist
                    if (location.search && isModalUrl(location.search)) {
                        // Check if a modal is opened and one of his components send a MODULE_OPENED. In this case, we have to take the existing parent tab.
                        pos = currentTabs.findIndex((x) => x.title !== moduleTab.title && x.url?.indexOf(location.pathname) !== -1)
                        if (pos !== -1) {
                            currentTab = { ...currentTab, title: currentTabs[pos].title }
                        }
                    }
                } else if (!isEqual(moduleTab, currentTabs[pos])) {
                    // Tab has changed
                    currentTab = {
                        ...currentTab,
                        isDefault: currentTabs[pos].isDefault,
                        vehicleDependency: currentTabs[pos].vehicleDependency,
                        moduleGroupId: currentTabs[pos].moduleGroupId,
                        moduleId: currentTabs[pos].moduleId,
                    }
                }


                if (!currentTab.isSelected) {
                    currentTab = { ...currentTab, isSelected: true }
                }

                const selectedPos = currentTabs.findIndex(x => x.isSelected)

                if (currentTab !== currentTabs[pos]) {
                    currentTabs = currentTabs.with(pos, currentTab as ModuleTab)
                }

                if (selectedPos !== -1 && selectedPos !== pos) {
                    currentTabs = currentTabs.with(selectedPos, {...currentTabs[selectedPos], isSelected: false})
                }

                return currentTabs
            })
        },
        [params.workTaskId, encodedWorktaskId, setTabs]
    )

    const resetTabs = useCallback(() => setTabs((prev) => prev.filter((x) => x.isDefault)), [setTabs])

    return { showTab, closeTab, closeTabs, resetTabs, resetVisibleTabCount, setTabs,  setVisibleTabCount }
}

