import { ErpIcon, ErpIconType } from "@tm/models"
import { useMemo } from "react"
import { styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { AvailabilitySymbol } from "../../availability"
import { Tooltip } from "../../../generics/tooltip"
import { IconButton } from "../../../generics/button"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(-1),
}))

type SpecialIconProps = {
    icon: ErpIcon
}

export function SpecialIcon({ icon }: SpecialIconProps) {
    const openPartDetails = useDefaultArticleItemState((x) => x.handleClickDetails)

    const handleClick = useMemo(() => {
        switch (icon.type) {
            case ErpIconType.SafetyDataSheets:
                return () => openPartDetails("safety-data-sheet")
            case ErpIconType.Documents:
                return () => openPartDetails("documents")
            case ErpIconType.ReplacementArticles:
                return () => openPartDetails("replacement-articles")
            // case ErpIconType.ProcurementCosts:
            // case ErpIconType.Promotional:
            // case ErpIconType.StockPart:
            // case ErpIconType.ReplenishmentArticle:
            // case ErpIconType.Deposit:
            // case ErpIconType.Hazmat:
            // case ErpIconType.NoOvernightShipping:
            // case ErpIconType.NonRefundable:
            // case ErpIconType.Discounted:
            // case ErpIconType.TransportationCosts:
            default:
        }
    }, [icon.type, openPartDetails])

    let control = (
        <AvailabilitySymbol
            imageUrl={icon.useIconFromUrl ? icon.url : undefined}
            iconName={!icon.useIconFromUrl ? icon.topmotiveId : undefined}
            height="22px"
            width="auto"
        />
    )

    if (icon.linkUrl) {
        control = (
            <StyledIconButton>
                <a href={icon.linkUrl} target="_blank" rel="noopener noreferrer">
                    {control}
                </a>
            </StyledIconButton>
        )
    } else if (handleClick) {
        control = <StyledIconButton onClick={handleClick}>{control}</StyledIconButton>
    }

    if (icon.description) {
        control = <Tooltip title={icon.description}>{control}</Tooltip>
    }

    return control
}
