import { styled } from "@mui/system"
import { TreeNode } from "@tm/models"
import { getUIA } from "@tm/utils"
import { TreeCategory } from "./TreeCategory"

const OverviewGrid = styled("div")({
    display: "grid",
    columnGap: "1em",
    rowGap: "1em",
    padding: "1em 1em 0.5em 1em",
})

type Props = {
    initialTree: TreeNode[]
    hideSecondLevel?: boolean
    variant: "default" | "small"
    nodeVariant?: "fastClick" | "default"
    onNodeClick: (node: TreeNode) => void
}

export function SearchTreeOverview(props: Props) {
    const gridTemplateColumns = props.variant === "small" ? "1fr 1fr" : props.nodeVariant === "fastClick" ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"
    return (
        <OverviewGrid {...getUIA("SearchtreeV2Overviewgrid")} sx={{ gridTemplateColumns }}>
            {props.initialTree.map((node) => {
                return (
                    <TreeCategory
                        key={`nodeKey_${node.id}`}
                        category={node}
                        onNodeClick={props.onNodeClick}
                        hideSecondLevel={props.hideSecondLevel}
                        nodeVariant={props.nodeVariant}
                    />
                )
            })}
        </OverviewGrid>
    )
}
