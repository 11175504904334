import { VehicleType, RepairTimeProvider, FittingPosition, WorkCategory, CostEstimationOptions } from ".";
import { VatRate } from "./vat-rate";

export type RepairTime = {
    providerWorkId: string
    displayNumber?: string
    categoryOfWork: number
    description: string
    time: number
    linkageId?: string
    sortNo: number
    note?: string
    isMaintenanceWork: boolean //Temporal property until MaintenanceRepairTime or similar is defined
    includedWorks: Array<IncludedWorkRequest>
    productGroupIds?: Array<number>
    fittingSide?: FittingPosition
}

export type IncludedWorkRequest = {
    providerWorkId: string
    isCalculable: boolean
    isVisible: boolean
    categoryOfWork: number
    description: string
    time: number
    sortNo: number
}

export type HasRepairTimesRequest = {
    repairTimeProvider: Array<RepairTimeProvider>
    modelId: number
    vehicleType: VehicleType
    productGroupIds: Array<number>
    fittingSideFilter?: FittingPosition
}

export type HasRepairTimesResponse = {
    productGroups: Record<number, RepairTimeProvider[]>
    errors?: string[]
}

export type GetMainServicesRequest = {
    repairTimeProvider: RepairTimeProvider
    modelId: number
    vehicleType: VehicleType
    query?: string
    nodeId?: number
    productGroupId?: string
    serviceIds?: string
    fittingSideFilter?: FittingPosition
    manufacturerId: number
    useManufacturerRepairTimeDivision: boolean
    defaultRepairTimeDivision: number
}

export type GetMainServicesResponse = {
    mainServices: Array<MainService>
    repairTimeDivision: number
}

export type MainService = ServiceBase & {
    noteId?: number
    note: ServiceNote
    attributes: Array<MainServiceAttribute>
    hasPreparationWorks: boolean
    preparationWorks?: Array<PreparationWork>
    parentNodeId?: number
    productGroupIds: Array<number>
    fittingSide?: FittingPosition
}

export type ServiceBase = {
    repairTimeNo: string
    repairTimeDisplayNo?: string
    description: string
    serviceType: string
    isCalculated: boolean
    sortId: number
    vehicleLinkageId?: number
    repairTime?: number
    hasCalculatedServices: boolean
    calculatedServices?: Array<CalculatedService>
}

export type RepairTimeRD = (MainService | PreparationWork) & {
    hourlyRate?: number
}

export type MainServiceAttribute = {
    id?: number
    value: string
    description?: string
    abbreviation?: string
    unit?: string
    isBlockSeparator?: boolean
}

export type ServiceNote = {
    id: number
    description: string
}

export type CalculatedService = ServiceBase & {
    isCalculable: boolean
    isVisible: boolean
}

export type PreparationWork = ServiceBase & {
    direction: ServiceDirection
}

export enum ServiceDirection {
    None = 0,
    Include = 1,
    Pre = 2,
    Post = 3,
    Exclude = 4
}

export type ShowRepairTimesByIdsRequest = {
    repairTimes: Array<RepairTimeRequest>
}

export type RepairTimeRequest = {
    id: string
    repairTimeProviderWorkId: string
    repairTimeProvider: RepairTimeProvider
    vehicleId: string
}

export type ShowRepairTimesByIdsResponse = {
    mainWorks: Array<MainWorkResponse>
    errors: Array<string>
}

export type MainWorkResponse = {
    id: string
    provider: RepairTimeProvider
    repairTimeProviderWorkId: string
    description: string
    categoryOfWorkShortCode: string
    sortId: number
    vehicleLinkageId?: number
    repairTime?: Date
    note: string
    productGroupIds: Array<number>
    includedWorks: Array<IncludedWorkResponse>
}

export type IncludedWorkResponse = {
    providerWorkId: string
    categoryOfWorkShortCode: string
    description: string
    repairTime: number
    sortId: number
}

export type ShowManufacturerDivisionRequest = {
    repairTimeProvider: RepairTimeProvider
    manufacturerId: number
}

export type ShowManufacturerDivisionResponse = {
    repairTimeDivision: number
}

export type AddCustomWorkListRequest = {
    workTaskId: string
    vehicleId?: string
    customWorks: Array<CustomWork>
    repairTimeDivision?: number
    repairTimeProvider?: RepairTimeProvider
    usePercentageValues?: boolean
    costEstimationOptions?: CostEstimationOptions
}

export type CustomWork = {
    customWorkNumber: string
    categoryOfWork: WorkCategory
    description: string
    time?: number
    hourlyRate?: number
    fixedPriceValue?: number
    rebate?: number
    surcharge?: number
    vatRate?: VatRate
}

export type RepairTimesProvidersNames = "awdoc" | "autodata" | "haynespro" | "tecrmi"

export type RepairTimeProviderConfig = {
    id: RepairTimesProvidersNames
    /** @deprecated Use `disabledFunctions` instead. */
    disabled?: boolean | string //Expression to determine whether the provider is disabled or not. Must be evaluated when rendering in the bundle
    disabledFunction?: string
    name?: string
    route?: string
    sort?: number
}