import * as React from "react"
import { bindMethodsToContext } from "../../helper";
import Text from "../text";
import Button from "../button";

export type StepProps = {
    id?: any
    checked?: boolean
    current?: boolean
    disabled?: boolean
    hideIcon?: boolean
    title: string
    description?: string
    onClick?: (name: string, id?: string | number) => void
    onCloseClick?: (name: string, id?: string | number) => void
}

export type StepState = {
    stepIcon: "check" | "error"
}

export class StepsContainer extends React.Component {
    render() {
        return <div className="steps">
            {this.props.children}
        </div>
    }
}

export default class Step extends React.Component<StepProps, StepState> {
    static Container = StepsContainer

    constructor(props: StepProps) {
        super(props)
        bindMethodsToContext(this, ["get","stop"])

        this.state = {
            stepIcon: "check"
        }
    }

    render() {
        const { hideIcon, checked, current, title, description } = this.props
        const { stepIcon } = this.state

        const baseName = "step"
        const qualifiedClass = this.getQualifiedClass(baseName)

        const headModifiers: any = []
        const textModifiers: any = []

        if(current) {
            headModifiers.push("light")
            textModifiers.push("light")
        }else {
            headModifiers.push("strong")
        }

        return (
            <div className={qualifiedClass} onClick={this.handleStepClick} >
                <div className={`${baseName}__inner`} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOver}>
                    <div className={`${baseName}__content`}>
                    {
                        this.props.children && this.props.children
                    }
                    {
                    !this.props.children &&
                        <>
                            <Text size="l" modifiers={headModifiers} className={`${baseName}__head`}>
                                {title}
                            </Text>
                            {description &&
                                <Text size="s" modifiers={textModifiers}>
                                    {description}
                                </Text>
                            }
                        </>
                    }
                    </div>

                        <div className={`${baseName}__badge ${baseName}__badge--${stepIcon}`}>
                        {
                            !hideIcon && checked && <Button layout={["ghost"]} icon={`${stepIcon}-filled`} onClick={this.handleCloseIconClick} />
                        }
                        </div>
                </div>
            </div>
        )
    }

    handleCloseIconClick(e: React.MouseEvent<SVGElement>) {
        const { disabled, onCloseClick, id } = this.props
        if(disabled) { return }

        // icon has no function, so it has to bubble
        this.stopPropagation(e)

        onCloseClick && onCloseClick(id)
    }

    handleStepClick(e: React.MouseEvent<HTMLDivElement>) {
        this.stopPropagation(e)
        const { disabled, onClick, id } = this.props
        if(disabled) { return }

        onClick && onClick(id)
    }

    stopPropagation(e: React.MouseEvent<any>) {
        e.stopPropagation()
        e.preventDefault()
        e.bubbles = false
    }

    handleMouseOver() {
        const { disabled, onCloseClick } = this.props
        if(disabled || !onCloseClick ) { return }

        this.setState((prevState: StepState) => {
            const stepIcon = (prevState.stepIcon == "check" ? "error" : "check")
            return { stepIcon }
        })
    }

    getQualifiedClass(baseName: string) {
        let qualifiedClass: string = `${baseName}`
        const { current, checked, disabled, description } = this.props

        if ( checked ) {
            qualifiedClass = `${qualifiedClass} ${baseName}--checked`
        }

        if( current ) {
            qualifiedClass = `${qualifiedClass} ${baseName}--current`
        }

        if(disabled || !current && !checked) {
            qualifiedClass = `${qualifiedClass} ${baseName}--disabled`
        }

        if(!description) {
            qualifiedClass = `${qualifiedClass} ${baseName}--no-description`
        }

        return qualifiedClass
    }
}
