import { AtomEffect, DefaultValue, atomFamily, selectorFamily } from "recoil"
import { isEqual } from "lodash"
import { RegisteredModels } from "@tm/models"
import type { ModuleNavigationState,  ViewState, ViewStateContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId } from "@tm/utils"
import { getNextUrlComparable, isSameTab } from "."

function createStateEffects(workTaskId: string | undefined): AtomEffect<ModuleNavigationState>[] {
    const container = Container.getInstance(RegisteredModels.ViewState) as ViewStateContainer
    return [
        function syncState({ node, onSet, setSelf, trigger, getLoadable }) {
            if (!workTaskId) {
                return
            }

            const encodedWorktaskId = encodeUniqueId(workTaskId)

            // Store on every change
            onSet((newValue, oldValue) => {
                const tabsToSave = newValue.tabs.map((x) => ({ ...x, isSelected: undefined }))
                if (
                    workTaskId &&
                    !(oldValue instanceof DefaultValue) &&
                    !isEqual(
                        tabsToSave,
                        oldValue.tabs.map((x) => ({ ...x, isSelected: undefined }))
                    )
                ) {
                    // Store in session for conflict dialog
                    sessionStorage.setItem(`${encodedWorktaskId}_openModules`, JSON.stringify(tabsToSave))

                    // Store in state management service
                    const viewState: ViewState = {
                        key: `${encodedWorktaskId}__module-navigation__`,
                        value: {
                            tabs: tabsToSave,
                        },
                    }
                    container.action("saveViewState")(viewState)
                }
            })

            // Initialize: load from database and merge it with already set tabs
            if (trigger === "get") {
                container.action("loadViewState")(`${encodedWorktaskId}__module-navigation__`).then((viewState: ViewState<ModuleNavigationState>) =>
                    setSelf((state) => {
                        // Merge loaded tabs with already opened ones
                        let currentTabs = (state as ModuleNavigationState).tabs
                        viewState.value.tabs
                            .filter((loadedTab) => !!loadedTab.title)
                            .forEach((loadedTab) => {
                                const tabIndex = currentTabs.findIndex((x) => isSameTab(x, loadedTab))
                                if (tabIndex === -1) {
                                    currentTabs = [...currentTabs, loadedTab]
                                } else {
                                    currentTabs = currentTabs.with(tabIndex, { ...loadedTab, ...currentTabs[tabIndex] })
                                }
                            })

                        // check current url and select tab if url matches
                        const currentUrl = `${location.pathname}${location.search}`
                        const currentUrlComparable = getNextUrlComparable(currentUrl)
                        const currentTabIndex = currentTabs.findIndex((tab) => {
                            const tabUrl = getNextUrlComparable(tab.url)
                            return currentUrlComparable && currentUrlComparable === tabUrl
                        })
                        if (currentTabIndex !== -1) {
                            currentTabs = currentTabs.with(currentTabIndex, {...currentTabs[currentTabIndex], isSelected: true})
                        }
                
                        return {
                            visibleTabCount: -1,
                            tabs: currentTabs,
                        }
                    })
                )
            }
        },
    ]
}

const moduleNavigationState = atomFamily<ModuleNavigationState, string | undefined>({
    key: "moduleNavigation",
    default: { tabs: [], visibleTabCount: -1 },
    effects: createStateEffects,
})

export const tabsSelector = selectorFamily<ModuleNavigationState["tabs"], string | undefined>({
    key: "tabsSelector",
    get:
        (workTaskId) =>
        ({ get }) =>
            get(moduleNavigationState(workTaskId)).tabs,
    set:
        (workTaskId) =>
        ({ set }, tabs) =>
            set(moduleNavigationState(workTaskId), (prev) => ({ ...prev, tabs } as ModuleNavigationState)),
})

export const visibleTabCountSelector = selectorFamily<ModuleNavigationState["visibleTabCount"], string | undefined>({
    key: "visibleTabCountSelector",
    get:
        (workTaskId) =>
        ({ get }) =>
            get(moduleNavigationState(workTaskId)).visibleTabCount,
    set:
        (workTaskId) =>
        ({ set }, visibleTabCount) =>
            set(moduleNavigationState(workTaskId), (prev) => ({ ...prev, visibleTabCount } as ModuleNavigationState)),
})

