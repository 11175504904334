import { useLocalization } from "@tm/localization"
import { ErpPrice, PriceType } from "@tm/models"
import { Fragment } from "react"
import { Box } from "@mui/material"
import { PriceValue, mapPriceVariant } from "./PriceValue"
import { PriceAddition } from "./PriceAddition"
import { PriceGrid } from "./PriceGrid"
import { ErpPriceAdditionalInfo } from "../../ErpPriceAdditionalInfo"
import { Typography } from "../../../generics/typographie"
import { ADDITIONAL_INFO_ICON_SIZE } from "../constants"

export type ErpPriceWithAdditionalInfo = ErpPrice & {
    onlyShowInfo?: boolean
}

type PricesProps = {
    prices: ErpPriceWithAdditionalInfo[]
}

export function Prices({ prices }: PricesProps) {
    const { translateText } = useLocalization()

    function renderRebateRow({ rebatePercent, type }: ErpPrice) {
        // don't render rebate for strikethrough prices
        if (!rebatePercent || type === PriceType.Strikethrough) {
            return
        }

        return <PriceAddition content={`${translateText(54)}: ${rebatePercent.round(0.01)}%`} />
    }

    function renderPrice(price: ErpPriceWithAdditionalInfo) {
        if (price.onlyShowInfo) {
            return (
                <>
                    {/* Empty box added to fill row */}
                    <Box sx={{ gridColumn: "span 2" }} />
                    <Box>
                        <ErpPriceAdditionalInfo additionalInfoIconSize={ADDITIONAL_INFO_ICON_SIZE} price={price} />
                    </Box>
                </>
            )
        }
        return (
            <>
                <Typography variant={mapPriceVariant(price.type)} lineHeight={1.2} maxLines={1} textAlign="right">
                    {price.shortDescription}
                </Typography>
                <PriceValue price={price} />
                <Box>
                    <ErpPriceAdditionalInfo additionalInfoIconSize={ADDITIONAL_INFO_ICON_SIZE} price={price} />
                </Box>
                {renderRebateRow(price)}
            </>
        )
    }

    return (
        <PriceGrid>
            {prices.map((price) => (
                <Fragment key={price.type}>{renderPrice(price)}</Fragment>
            ))}
        </PriceGrid>
    )
}
