import {
    RepairTimeProvider,
    MainService,
    RepairTime,
    AddRepairTimeListRequest,
    CalculatedService,
    IncludedWorkRequest,
    WorkCategory,
    PreparationWork,
    ServiceBase,
    ServiceDirection,
    RepairTimesProvidersNames,
    UserContext,
    Vehicle,
    Customer,
    VehicleType,
} from "@tm/models"

/**
 * @deprecated Use getRepairTimesProviderStringByEnum
 */
export function getArticleRelatedRepairTimesProviderStringByEnum(provider: RepairTimeProvider) {
    switch (provider) {
        case RepairTimeProvider.AwDocCar:
            return "awdoc"
        case RepairTimeProvider.Autodata:
            return "autodata"
        case RepairTimeProvider.HaynesProCar:
            return "haynespro"
        default:
            return undefined
    }
}

/**
 *
 * @deprecated Use getRepairTimesProviderEnumByString
 */
export function getArticleRelatedRepairTimesProviderEnumByString(provider: string) {
    switch (provider) {
        case "awdoc":
            return RepairTimeProvider.AwDocCar
        case "autodata":
            return RepairTimeProvider.Autodata
        case "haynespro":
            return RepairTimeProvider.HaynesProCar
        default:
            return undefined
    }
}

/**
 *
 * @deprecated Use getRepairTimesProvider
 */
export function getArticleRelatedRepairTimesProvider(
    availableProvidersForPG: Array<RepairTimeProvider>,
    sortableProviders: Array<{ id: RepairTimesProvidersNames; sort?: number }>,
    activeProvider?: RepairTimesProvidersNames
): RepairTimesProvidersNames | undefined {
    let provider: RepairTimesProvidersNames | undefined

    const articleRelatedRTProvider = activeProvider && getArticleRelatedRepairTimesProviderEnumByString(activeProvider)

    if (
        activeProvider &&
        activeProvider !== "tecrmi" &&
        articleRelatedRTProvider &&
        availableProvidersForPG.indexOf(articleRelatedRTProvider) !== -1
    ) {
        provider = activeProvider as RepairTimesProvidersNames
    } else {
        const sortedAwProviders = sortableProviders.orderBy((x) => (x.sort != null ? x.sort : Number.MAX_VALUE))

        const foundProvider = sortedAwProviders.find((x) => {
            const prov = getArticleRelatedRepairTimesProviderEnumByString(x.id)
            if (!prov) {
                return false
            }
            return availableProvidersForPG.indexOf(prov) !== -1
        })

        if (foundProvider && foundProvider.id !== "tecrmi") {
            provider = foundProvider.id as RepairTimesProvidersNames
        }
    }

    return provider
}

export function getRepairTimesProviderStringByEnum(provider: RepairTimeProvider) {
    switch (provider) {
        case RepairTimeProvider.AwDocCar:
            return "awdoc"
        case RepairTimeProvider.Autodata:
            return "autodata"
        case RepairTimeProvider.HaynesProCar:
            return "haynespro"
        case RepairTimeProvider.TecRmiCar:
            return "tecrmi"
        default:
            return undefined
    }
}

export function getRepairTimesProviderEnumByString(provider: string | undefined) {
    switch (provider) {
        case "awdoc":
            return RepairTimeProvider.AwDocCar
        case "autodata":
            return RepairTimeProvider.Autodata
        case "haynespro":
            return RepairTimeProvider.HaynesProCar
        case "tecrmi":
            return RepairTimeProvider.TecRmiCar
        default:
            return undefined
    }
}

export function getRepairTimesProvider(
    availableProvidersForPG: Array<RepairTimeProvider>,
    sortableProviders: Array<{ id: RepairTimesProvidersNames; sort?: number }>,
    activeProvider?: RepairTimesProvidersNames
): RepairTimesProvidersNames | undefined {
    let provider: RepairTimesProvidersNames | undefined

    const articleRelatedRTProvider = activeProvider && getRepairTimesProviderEnumByString(activeProvider)

    if (activeProvider && articleRelatedRTProvider && availableProvidersForPG.indexOf(articleRelatedRTProvider) !== -1) {
        provider = activeProvider as RepairTimesProvidersNames
    } else {
        const sortedAwProviders = sortableProviders.orderBy((x) => (x.sort != null ? x.sort : Number.MAX_VALUE))

        const foundProvider = sortedAwProviders.find((x) => {
            const prov = getRepairTimesProviderEnumByString(x.id)
            if (!prov) {
                return false
            }
            return availableProvidersForPG.indexOf(prov) !== -1
        })

        if (foundProvider) {
            provider = foundProvider.id as RepairTimesProvidersNames
        }
    }

    return provider
}

export function mapMainServicesToAddRepairTimeListRequest(
    workTaskId: string,
    provider: RepairTimeProvider,
    mainServices: Array<MainService>,
    vehicleId: string,
    repairTimeDivision: number
): AddRepairTimeListRequest {
    return {
        workTaskId,
        provider,
        vehicleId,
        repairTimes: mainServices.map(mapMainServiceToRepairTime),
        repairTimeDivision,
    }
}

export function mapMainServiceToRepairTime(mainService: MainService): RepairTime {
    let includedWorks = mapCalculatedServicesToIncludedWorkRequest(mainService.calculatedServices)
    if (!includedWorks) {
        includedWorks = mapPreparationWorksToIncludedWorkRequest(mainService.preparationWorks)
    }

    return {
        providerWorkId: mainService.repairTimeNo,
        displayNumber: mainService.repairTimeDisplayNo,
        categoryOfWork: mapCategoryOfWork(mainService.serviceType),
        description: mainService.description,
        time: mainService.repairTime || 0,
        linkageId: mainService.vehicleLinkageId ? mainService.vehicleLinkageId.toString() : undefined,
        sortNo: mainService.sortId,
        note: mainService.note ? mainService.note.description : undefined,
        isMaintenanceWork: false,
        includedWorks: includedWorks || [],
        productGroupIds: mainService.productGroupIds,
        fittingSide: mainService.fittingSide,
    }
}

function mapCalculatedServicesToIncludedWorkRequest(
    calculatedServices: Array<CalculatedService> | undefined
): Array<IncludedWorkRequest> | undefined {
    if (!calculatedServices || !calculatedServices.length) {
        return undefined
    }

    return calculatedServices.map((calcService) => {
        return {
            providerWorkId: calcService.repairTimeNo,
            isCalculable: calcService.isCalculable,
            isVisible: calcService.isVisible,
            categoryOfWork: mapCategoryOfWork(calcService.serviceType),
            description: calcService.description,
            time: calcService.repairTime || 0,
            sortNo: calcService.sortId,
        }
    })
}

function mapPreparationWorksToIncludedWorkRequest(preparationWorks: Array<PreparationWork> | undefined): Array<IncludedWorkRequest> | undefined {
    const includedPreparationWorks =
        preparationWorks && preparationWorks.filter((preparationWork) => preparationWork.direction === ServiceDirection.Include)
    if (!includedPreparationWorks || !includedPreparationWorks.length) {
        return undefined
    }

    return includedPreparationWorks.map((calcService) => {
        return {
            providerWorkId: calcService.repairTimeNo,
            isCalculable: true,
            isVisible: true,
            categoryOfWork: mapCategoryOfWork(calcService.serviceType),
            description: calcService.description,
            time: calcService.repairTime || 0,
            sortNo: calcService.sortId,
        }
    })
}

export function mapCategoryOfWork(categoryOfWork: string): WorkCategory {
    switch (categoryOfWork) {
        case "KA":
            return WorkCategory.BodyWork
        case "ZB":
            return WorkCategory.AccessoryWork
        case "LA":
            return WorkCategory.PaintWork
        case "EL":
            return WorkCategory.ElectricalWork
        case "EK":
            return WorkCategory.ElectronicWork
        case "SA":
            return WorkCategory.SaddlerWork
        case "SR":
            return WorkCategory.SmartRepair
        case "DW":
            return WorkCategory.Diagnose
        case "WS":
        default:
            return WorkCategory.WorkshopWork
    }
}

export function getCategoryOfWorkDescriptionTextId(categoryOfWork: string | WorkCategory): number {
    switch (categoryOfWork) {
        case "KA":
        case WorkCategory.BodyWork:
            return 249
        case "ZB":
        case WorkCategory.AccessoryWork:
            return 250
        case "LA":
        case WorkCategory.PaintWork:
            return 251
        case "EL":
        case WorkCategory.ElectricalWork:
            return 252
        case "EK":
        case WorkCategory.ElectronicWork:
            return 253
        case "SA":
        case WorkCategory.SaddlerWork:
            return 929
        case "SR":
        case WorkCategory.SmartRepair:
            return 930
        case "DW":
        case WorkCategory.Diagnose:
            return 12981
        case "WS":
        case WorkCategory.WorkshopWork:
        default:
            return 248
    }
}

export function mapPreparationWorksToAddRepairTimeListRequest(
    workTaskId: string,
    provider: RepairTimeProvider,
    preparationWorks: Array<PreparationWork>,
    vehicleId: string,
    repairTimeDivision: number
): AddRepairTimeListRequest {
    return {
        workTaskId,
        provider,
        vehicleId,
        repairTimes: preparationWorks.map(mapPreparationWorkToRepairTime),
        repairTimeDivision,
    }
}

function mapPreparationWorkToRepairTime(prepWork: PreparationWork): RepairTime {
    return mapServiceBaseToRepairTime(prepWork)
}

export function mapServiceBaseToRepairTime(work: ServiceBase): RepairTime {
    return {
        providerWorkId: work.repairTimeNo,
        displayNumber: work.repairTimeDisplayNo,
        categoryOfWork: mapCategoryOfWork(work.serviceType),
        description: work.description,
        time: work.repairTime || 0,
        linkageId: work.vehicleLinkageId ? work.vehicleLinkageId.toString() : undefined,
        sortNo: work.sortId,
        isMaintenanceWork: false,
        includedWorks: mapCalculatedServicesToIncludedWorkRequest(work.calculatedServices) || [],
    }
}

export function getRepairTimeProvidersByNames(repairTimesProvidersNames: Array<RepairTimesProvidersNames>) {
    const rtProviders = []
    if (repairTimesProvidersNames.indexOf("awdoc") !== -1) {
        rtProviders.push(RepairTimeProvider.AwDocCar)
    }
    if (repairTimesProvidersNames.indexOf("autodata") !== -1) {
        rtProviders.push(RepairTimeProvider.Autodata)
    }
    if (repairTimesProvidersNames.indexOf("haynespro") !== -1) {
        rtProviders.push(RepairTimeProvider.HaynesProCar)
    }
    if (repairTimesProvidersNames.indexOf("tecrmi") !== -1) {
        rtProviders.push(RepairTimeProvider.TecRmiCar)
    }
    return rtProviders
}

export type ConfigFunctionType = (userContext: UserContext, vehicle?: Vehicle, customer?: Customer) => boolean | string

export const ConfigFunctions: Record<string, ConfigFunctionType> = {
    "HAS-VEHICLE": (_, vehicle) => !!vehicle,

    "HAS-NO-VEHICLE": (_, vehicle) => !vehicle,

    "HAS-NO-VEHICLE-VIN": (_, vehicle) => !vehicle || !vehicle?.vin,

    "EDS-DISABLED": (_, vehicle) => (vehicle ? !vehicle?.vin : false),

    "DRIVEMOTIVE-DISABLED": (_, vehicle, customer) => !!(vehicle || customer),

    "REPAIRTIMES-HAYNESPRO-DISABLED": (_, vehicle?) =>
        !(
            (vehicle?.vehicleType === VehicleType.PassengerCar || vehicle?.vehicleType === VehicleType.CommercialVehicle) &&
            vehicle.dataAvailabilities?.repairTimes.haynesPro
        ),

    "REPAIRTIMES-AWDOC-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.repairTimes.awDoc || vehicle.vehicleType === VehicleType.Motorcycle,

    "REPAIRTIMES-TECRMI-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.repairTimes.tecRMI || vehicle.vehicleType === VehicleType.Motorcycle,

    "REPAIRTIMES-AUTODATA-DISABLED": (_, vehicle) =>
        !vehicle?.dataAvailabilities?.repairTimes.autodata || vehicle.vehicleType === VehicleType.Motorcycle,

    "TECHDATA-HAYNESPRO-DISABLED": (_, vehicle?) =>
        !(
            (vehicle?.vehicleType === VehicleType.PassengerCar || vehicle?.vehicleType === VehicleType.CommercialVehicle) &&
            vehicle.dataAvailabilities?.technicalData.haynesPro
        ),

    "TECHDATA-AUTODATA-DISABLED": (_, vehicle) =>
        !vehicle?.dataAvailabilities?.technicalData.autodata || vehicle.vehicleType === VehicleType.Motorcycle,

    "TECHDATA-TECRMI-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.technicalData.tecRMI || vehicle.vehicleType === VehicleType.Motorcycle,

    "TECHDATA-ALLDATA-DISABLED": (_, vehicle) => !vehicle || vehicle.vehicleType === VehicleType.Motorcycle,

    "TECHDATA-INSPECTIONDATA-HAYNESPRO-DISABLED": (_, vehicle?) =>
        !(
            (vehicle?.vehicleType === VehicleType.PassengerCar &&
                (vehicle.dataAvailabilities?.technicalData.haynesPro || vehicle.dataAvailabilities?.inspectionData.haynesPro)) ||
            (vehicle?.vehicleType === VehicleType.CommercialVehicle && vehicle.dataAvailabilities?.technicalData.haynesPro)
        ),

    "TECHDATA-INSPECTIONDATA-AUTODATA-DISABLED": (_, vehicle) =>
        !(vehicle?.dataAvailabilities?.technicalData.autodata || vehicle?.dataAvailabilities?.inspectionData.autodata) ||
        vehicle.vehicleType === VehicleType.Motorcycle,

    "TECHDATA-INSPECTIONDATA-TECRMI-DISABLED": (_, vehicle) =>
        !(vehicle?.dataAvailabilities?.technicalData.tecRMI || vehicle?.dataAvailabilities?.inspectionData.tecRMI) ||
        vehicle.vehicleType === VehicleType.Motorcycle,

    "INSPECTIONDATA-AUTODATA-DISABLED": (_, vehicle) =>
        !vehicle?.dataAvailabilities?.inspectionData.autodata || vehicle.vehicleType === VehicleType.Motorcycle,

    "INSPECTIONDATA-HAYNESPRO-DISABLED": (_, vehicle?) =>
        !(
            (vehicle?.vehicleType === VehicleType.PassengerCar || vehicle?.vehicleType === VehicleType.CommercialVehicle) &&
            vehicle.dataAvailabilities?.inspectionData.haynesPro
        ),

    "INSPECTIONDATA-TECRMI-DISABLED": (_, vehicle) =>
        !vehicle?.dataAvailabilities?.inspectionData.tecRMI || vehicle.vehicleType === VehicleType.Motorcycle,

    "GPI-TEMOT-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.gpi.temot,

    "GPI-DAT-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.gpi.dat,

    "GPI-TECRMI-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.gpi.tecRMI,

    "GPI-EUROTAX-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.gpi.eurotaxV3,

    "TYRES-TECRMI-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.tyres.tecRMI,

    "FASTCALC-ANY-DISABLED": (_, vehicle) => !vehicle?.dataAvailabilities?.fastCalculator.hasAny,
    "FASTSERVICE-DISABLED": (_, vehicle) =>
        !vehicle?.dataAvailabilities?.inspectionData.haynesPro || !vehicle?.dataAvailabilities?.technicalData.haynesPro,
}
