import * as React from "react"
import { UserProviderContext } from "../model/UserProviderContext";
import { User } from "../model";
import { Omit } from "@tm/utils";

export type WithChangeUserSettingProps = Pick<User, "userSettings" | "reloadUserSettings" | "setUserSetting" | "changeLogo" | "changePrintLogo" | "removeLogo" | "changeHourlyRates" | "updateRepairShop" | "changeRepairTimeOptions" | "changeOrderOptions" | "changeDirectBuyOptions">

export function withChangeUserSetting<P extends WithChangeUserSettingProps>(component: React.ComponentType<P>): React.ComponentClass<Omit<P, keyof WithChangeUserSettingProps>> {
    return class extends React.Component<Omit<P, keyof WithChangeUserSettingProps>> {
        constructor(props: Omit<P, keyof WithChangeUserSettingProps>) {
            super(props)
            this.renderInnerComponent = this.renderInnerComponent.bind(this)
        }

        renderInnerComponent(user: User) {
            const currentProps: any = this.props || {}

            const props: P & WithChangeUserSettingProps = {
                ...currentProps,
                userSettings: user.userSettings,
                reloadUserSettings: user.reloadUserSettings,
                setUserSetting: user.setUserSetting,
                changeLogo: user.changeLogo,
                changePrintLogo: user.changePrintLogo,
                removeLogo: user.removeLogo,
                changeHourlyRates: user.changeHourlyRates,
                updateRepairShop: user.updateRepairShop,
                changeRepairTimeOptions: user.changeRepairTimeOptions,
                changeOrderOptions: user.changeOrderOptions,
                changeDirectBuyOptions: user.changeDirectBuyOptions,
            }

            return React.createElement(component, props)
        }

        render() {
            return (
                <UserProviderContext.Consumer>
                    {this.renderInnerComponent}
                </UserProviderContext.Consumer>
            )
        }
    }
}
