import { memo, useCallback, useEffect, useRef } from "react"
import { Box, ClickAwayListener, Tooltip as MuiTooltip } from "@mui/material"
import { TooltipProps } from "./index"
import { useDebounce } from "../../hooks/useDebounce"

export const TOOLTIP_DEFAULT_DELAY = 500

export const TooltipComponent = memo<TooltipProps>((props) => {
    const { className, onClickAway, noDelay, ...rest } = props
    const tooltipRef = useRef<HTMLDivElement>(null)

    const wheelEventHandler = useDebounce<WheelEvent>((e) => onClickAway?.(e), 50)

    const scrollEventHandler = useCallback(
        (e: WheelEvent) => {
            if (tooltipRef.current && !tooltipRef.current?.contains(e.target as Node)) {
                wheelEventHandler(e)
            }
        },
        [tooltipRef, wheelEventHandler]
    )

    useEffect(() => {
        if (window.addEventListener && props.open) {
            if (!props.preventCloseOnScroll) {
                window.addEventListener("wheel", scrollEventHandler)
            }
        }

        return () => {
            window.removeEventListener("wheel", scrollEventHandler)
        }
    }, [scrollEventHandler, props.preventCloseOnScroll, props.open])

    const tooltipProps = {
        ...rest,
        enterDelay: noDelay ? undefined : props.enterDelay ?? TOOLTIP_DEFAULT_DELAY,
        enterNextDelay: noDelay ? undefined : props.enterNextDelay ?? TOOLTIP_DEFAULT_DELAY,
    }

    if (onClickAway) {
        return (
            <ClickAwayListener onClickAway={onClickAway}>
                {/* Div shall not be removed since the click in one item will instantly close the tooltip. In the case of compilations a new one couldn't be created */}
                <Box ref={tooltipRef}>
                    <MuiTooltip arrow {...tooltipProps} classes={{ popper: className }}>
                        {props.children}
                    </MuiTooltip>
                </Box>
            </ClickAwayListener>
        )
    }

    return (
        <MuiTooltip arrow {...tooltipProps} classes={{ popper: className }}>
            {props.children}
        </MuiTooltip>
    )
})
