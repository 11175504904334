import { ErpIcon, ErpIconType } from "@tm/models"
import { useMemo } from "react"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { SpecialIcon } from "./ErpContainer/SpecialIcon"

type Props = {
    position: ErpIconPosition
}

export function ErpIcons({ position }: Props) {
    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const erpInfo = articleErpInfos?.default?.state === "success" ? articleErpInfos.default.response : undefined

    const specialIcons = useMemo(() => erpInfo?.specialIcons?.filter((x) => getIconPosition(x) === position) ?? [], [erpInfo?.specialIcons, position])

    if (!specialIcons.length) {
        return null
    }

    return (
        <>
            {specialIcons.map((x) => (
                <SpecialIcon key={x.url ?? x.topmotiveId} icon={x} />
            ))}
        </>
    )
}

type ErpIconPosition = "erpInfo" | "articleInfo"

function getIconPosition(icon: ErpIcon): ErpIconPosition {
    switch (icon.type) {
        case ErpIconType.SafetyDataSheets:
        case ErpIconType.Documents:
        case ErpIconType.ReplacementArticles:
            return "articleInfo"
        case ErpIconType.ProcurementCosts:
        case ErpIconType.Promotional:
        case ErpIconType.StockPart:
        case ErpIconType.ReplenishmentArticle:
        case ErpIconType.Deposit:
        case ErpIconType.Hazmat:
        case ErpIconType.NoOvernightShipping:
        case ErpIconType.NonRefundable:
        case ErpIconType.Discounted:
        case ErpIconType.TransportationCosts:
        default:
            return "erpInfo"
    }
}
