import { forwardRef, ImgHTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { SvgFallback } from "./fallbacks/svg-fallback"
import { ArticleFallback } from "./fallbacks/article-fallback"
import { ManufacturerFallback } from "./fallbacks/manufacturer-fallback"
import { CarFallback } from "./fallbacks/car-fallback"
import { BikeFallback } from "./fallbacks/bike-fallback"
import { FlagFallback } from "./fallbacks/Flag"

/**
 * @param src Image source
 * @param type used to show a fallback image (rename to fallbackType?)
 */
type BaseProps = ImgHTMLAttributes<HTMLImageElement> & {
    src: string
    type?: "article" | "manufacturer" | "car" | "bike" | "flag"
}

export const Image = forwardRef<HTMLImageElement, BaseProps>((props, ref) => {
    const { onError } = props
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        setError(false)
    }, [props.src])

    const handleError = useCallback(
        (e: SyntheticEvent<HTMLImageElement, Event>) => {
            setError(true)
            onError?.(e)
        },
        [onError]
    )

    const fallbackImage = useMemo(() => {
        let fallback

        switch (props.type) {
            case "article":
                fallback = <ArticleFallback />
                break
            case "manufacturer":
                fallback = <ManufacturerFallback />
                break
            case "car":
                fallback = <CarFallback />
                break
            case "bike":
                fallback = <BikeFallback />
                break
            case "flag":
                fallback = <FlagFallback alt={props.alt || ""} title={props.title} />
                break
            default:
                fallback = <SvgFallback />
                break
        }

        return (
            <span className={`error-image error-image__${props.type} ${props.className || ""}`} title={props.title}>
                {fallback}
            </span>
        )
    }, [props.type, props.className, props.title])

    if (error || !props?.src) {
        return fallbackImage
    }

    return <img {...props} alt={props.alt || ""} ref={ref} className={`image${props.className ? ` ${props.className}` : ""}`} onError={handleError} />
})
