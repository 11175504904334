import { DefaultArticleItem } from "./variants/DefaultArticleItem"
import { OeInformationItem } from "./variants/OeInformationItem"
import { OeArticleItem } from "./variants/OeArticleItem"

export { ArticleItemStateProvider } from "./ContextProviderStates"

export * from "./Transactions/ReplaceButton"

/**
 * Based on the `variant` the properties are used from the underlying component.
 * If `variant` is not supplied it is assumed to be `PartsList`.
 */
type ArticleItemProps = { variant: "default" | "KVA" | "Basket" | "OE" | "OeInformation" }

export function ArticleItem({ variant }: ArticleItemProps) {
    switch (variant) {
        case "KVA":
            return null
        case "Basket":
            return null
        case "OE":
            return <OeArticleItem />
        case "OeInformation":
            return <OeInformationItem />
        case "default":
        default:
            return <DefaultArticleItem />
    }
}
