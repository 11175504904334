import { NextTheme } from "./themeTypes"

export const DEFAULT_THEME: NextTheme = {
    name: "default",
    colors: {
        primary: "#2196f3",
        secondary: "#2a517f",
        highlight: "#f3ae21",
        success: "#c2e617",
        warning: "#ffeb3b",
        info: "#0288d1",
        danger: "#f44336",
        dark: "#202020",
        light: "#fff",
        header: "#2c63c9",
        background: "#fff",
        text: "#494949",
        deepBlack: "#000000",
    },
    opacity: {
        primary: 0.87,
        secondary: 0.54,
        disabled: 0.38,
    },
    margin: {
        xs: "0.1em",
        s: "0.25em",
        m: "0.5em",
        l: "0.75em",
        xl: "1em",
    },
    box: {
        radius: "3px",
    },
    radius: {
        default: "3px",
    },
    font: {
        fontFamily: {
            primary: "Open Sans, Arial, Helvetica, sans-serif",
            secondary: "Roboto Sans, Arial, Helvetica, sans-serif",
            headlines: "Open Sans, Arial, Helvetica, sans-serif",
            serif: "Georgia, Times, serif",
        },
        boldWeight: 600,
        lineHeight: 1.2,
        textSize: {
            xs: "0.75rem",
            s: "0.875rem",
            m: "1rem",
            l: "1.125rem",
            xl: "1.25rem",
        },
        subtitleSize: {
            xl: "1.0625rem",
            l: "0.9375rem",
            m: "0.8125rem",
            s: "0.6825rem",
            xs: "0.5625rem",
        },
        headlineSize: {
            xl: "2.5rem",
            l: "2rem",
            m: "1.75rem",
            s: "1.5rem",
            xs: "1.25rem",
        },
        h1: {
            fontSize: "24px",
            fontWeight: 400,
            color: "#202020",
        },
        h2: {
            fontSize: "22px",
            fontWeight: 400,
            color: "#202020",
        },
        h3: {
            fontSize: "20px",
            fontWeight: 400,
            color: "#202020",
        },
        h4: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#202020",
        },
        button: {
            fontSize: "14px",
        },
        body1: {
            fontSize: "16px",
            color: "#202020",
        },
        body2: {
            fontSize: "14px",
            color: "#202020",
        },
        body3: {
            fontSize: "12px",
            color: "#202020",
        },
        label: {
            fontSize: "12px",
            color: "#202020",
        },
    },
    animation: {
        transition: "all .2s ease",
    },
    pageNavigation: {
        layout: "",
        itemColor: "",
        itemHoverColor: "",
        itemBorderLeftColor: "",
    },
    breakpoints: {
        s: "640px",
        m: "1024px",
        l: "1280px",
        xl: "1680px",
    },
}
