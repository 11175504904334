import { Box } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpInformation } from "@tm/models"
import { concat } from "@tm/utils"
import { Typography } from "../../../generics/typographie"

type PriceUnitProps = {
    erpInfo?: ErpInformation
}

export function PriceUnit({ erpInfo }: PriceUnitProps) {
    const { translateText } = useLocalization()

    const { quantityPackingUnit, quantityUnit } = erpInfo?.quantity ?? {}

    if (!erpInfo || !quantityPackingUnit || quantityPackingUnit === 1) {
        return null
    }

    return (
        <Box textAlign="right">
            <Typography variant="body3">{concat(" ", translateText(1312), quantityPackingUnit, quantityUnit)}</Typography>
        </Box>
    )
}
