import { useMemo } from "react"
import { useQuery, useQueryClient } from "react-query"
import { Container } from "@tm/nexus"
import { RegisteredModels, RegistrationNoType, ShowConfigResponse } from "@tm/models"

const showOptionsKey = "vehicle_show_options"
export function useGetAvailableVehicleSearchOptions() {
    const { data, isLoading, isIdle } = useQuery(
        [showOptionsKey],
        () => Container.getInstance<ShowConfigResponse>(RegisteredModels.Vehicle_ShowOptions).subscribe().load(),
        {
            staleTime: Infinity,
        }
    )

    return {
        availableVehicleSearches: data,
        isLoading,
        isIdle,
    }
}

export const SkippedRegNoSearches = [
    RegistrationNoType.None,
    RegistrationNoType.KeywordSearch,
    RegistrationNoType.RückwFahrzeugVkn,
    RegistrationNoType.KTypNr,
    RegistrationNoType.NkwId,
    RegistrationNoType.VehicleBase,
    RegistrationNoType.Motorcode,
    RegistrationNoType.Fahrzeugbaum,
    RegistrationNoType.DatVin,
    RegistrationNoType.TopmotiveVin,
    RegistrationNoType.TopmotiveTypeId,
]

export function useAvailableVehicleSearchOptions() {
    const queryClient = useQueryClient()
    const { availableVehicleSearches: vehicleSearches, isLoading, isIdle } = useGetAvailableVehicleSearchOptions()

    const availableVehicleSearches = useMemo(() => {
        if (!vehicleSearches) {
            return { configuredVehicleLookups: [] }
        }
        return vehicleSearches
    }, [vehicleSearches])

    const invalidateStore = () => {
        queryClient.invalidateQueries(showOptionsKey)
    }

    return { availableVehicleSearches, isLoading, isIdle, invalidateStore }
}

export function useAvailableVehicleSearchOptionsRegNoTypes() {
    const { availableVehicleSearches } = useGetAvailableVehicleSearchOptions()

    const regNoTypes = useMemo(() => {
        if (!availableVehicleSearches) {
            return []
        }

        const types: Array<RegistrationNoType> = []

        availableVehicleSearches.configuredVehicleLookups.forEach((x) => {
            if (x.isUsedInDefaultSearch) {
                types.push(x.lookupTypeId)
            }
        })

        return types
    }, [availableVehicleSearches])

    return regNoTypes
}
