import { useEffect, useMemo } from "react"
import { useLocation } from "react-router"
import { values } from "lodash"
import { ModuleTab } from "@tm/models"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { getNextUrlComparable } from "."
import { useModuleNavigation } from "./useModuleNavigation"
import { useWorkTaskId } from "../worktask"
import { useRecoilState } from "recoil"
import { tabsSelector, visibleTabCountSelector } from "./state"

export function useInitModuleNavigation(activateDefaultTabs?: boolean, defaultTabs?: Record<string, ModuleTab>) {
    const workTaskId = useWorkTaskId()
    const location = useLocation()
    
    const [tabs, setTabs] = useRecoilState(tabsSelector(workTaskId))
    const [visibleTabCount, setVisibleTabCount] = useRecoilState(visibleTabCountSelector(workTaskId))

    const { closeTab, resetVisibleTabCount } = useModuleNavigation()

    const visibleTabs = useMemo(() => {
        if (visibleTabCount === -1) {
            return tabs 
        }
        const selectedPos = tabs.findIndex(x => x.isSelected)
        if (selectedPos >= visibleTabCount) {
            return [...tabs.slice(0, visibleTabCount - 1), tabs[selectedPos]]
        }
        return tabs.slice(0, visibleTabCount)
    }, [visibleTabCount, tabs])

    const moreTabs = useMemo(() => tabs.filter(x => !visibleTabs.includes(x)), [visibleTabCount, tabs])


    useEffect(
        function initDefaultTabs() {
            if (!activateDefaultTabs || !workTaskId || tabs?.some(val => val.isDefault)) {
                return
            }
            const defaultTabsWithName = values(defaultTabs).filter((tab) => !!tab.name)
            if (!defaultTabsWithName.length) {
                return
            }
            setTabs(defaultTabsWithName.map(tab => ({
                icon: tab.icon,
                title: tab.name!,
                url: renderRoute(tab.url, { workTaskId: encodeUniqueId(workTaskId) }),
                isSelected: false,
                isDefault: true,
                vehicleDependency: tab.vehicleDependency,
                moduleGroupId: tab.moduleGroupId,
                moduleId: tab.moduleId,
            })))
        }, 
        [defaultTabs, activateDefaultTabs]
    )

    useEffect(
        function changeTabUrl() {
            const currentUrl = `${location.pathname}${location.search}`
            const currentUrlComparable = getNextUrlComparable(currentUrl)
            setTabs((prev) => {
                let currentTabs = prev
                const currentTabIndex = currentTabs.findIndex((tab) => {
                    const tabUrl = getNextUrlComparable(tab.url)
                    return currentUrlComparable && currentUrlComparable === tabUrl
                })
                const selectedIndex = currentTabs.findIndex((x) => x.isSelected)
                if (selectedIndex !== -1) {
                    currentTabs = currentTabs.with(selectedIndex, { ...currentTabs[selectedIndex], isSelected: false })
                }
                if (currentTabIndex !== -1) {
                    if (currentTabs[currentTabIndex].url !== currentUrl || !currentTabs[currentTabIndex].isSelected) {
                        currentTabs = currentTabs.with(currentTabIndex, {
                            ...currentTabs[currentTabIndex],
                            url: currentUrl,
                            isSelected: true,
                        })
                    }
                }
                return currentTabs
            })
        },
        [location]
    )

    return { visibleTabs, moreTabs, closeTab, visibleTabCount, setVisibleTabCount, resetVisibleTabCount }
}

