import { ECounterType } from "@tm/models"
import { TmaEvent, TrackingModel } from "../../models/models"
import { TmaSender } from "../tma-sender"
import { CounterPayload, GeneralCountClick } from "./models"

export class GeneralCountEventHandler extends TmaSender<GeneralCountClick> implements TmaEvent {
    key = "GENERAL_COUNT"

    static serviceEndpoint = "general/countSubmitted"

    constructor() {
        super(GeneralCountEventHandler.serviceEndpoint)

        this.storage.getContextId = () => ECounterType.Undefined.toString()
    }

    handle = (trackingData: TrackingModel<CounterPayload>): boolean => {
        if (!trackingData.tmaEvent || trackingData.tmaEvent !== this.key || !trackingData.payload) {
            return false
        }
        
        const { counterType } = trackingData.payload

        // this is needed to get the correct context! Do not remove!
        this.storage.getContextId = () => counterType.toString()

        const temporaryRequestBody = this.requestBody

        temporaryRequestBody.counterType = counterType
        temporaryRequestBody.counterValue = (temporaryRequestBody.counterValue ?? 0) + 1

        this.requestBody = temporaryRequestBody

        this.sendEvent(temporaryRequestBody).catch()

        return true
    }
}
