export function plateCountryCodeMapper(shortCountryCode: string) {
    switch (shortCountryCode.toLowerCase()) {
        case "no": {
            return "N"
        }
        case "pt": {
            return "P"
        }
        case "de": {
            return "D"
        }
        case "ie": {
            return "IRL"
        }
        case "fr": {
            return "F"
        }
        case "fi": {
            return "FIN"
        }
        case "es": {
            return "E"
        }
        case "se": {
            return "S"
        }
        case "ee": {
            return "EST"
        }
        case "it": {
            return "I"
        }
        default: {
            return shortCountryCode.toUpperCase()
        }
    }
}
