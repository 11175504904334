import { Alert } from "../alert"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

export function Notifications() {
    const notifications = useDefaultArticleItemState((x) => x.notifications)

    if (!notifications?.length) {
        return null
    }

    return (
        <>
            {notifications?.map((note, index) => {
                return (
                    <Alert
                        key={`articleNote_${index}`}
                        variant="outlined"
                        size="small"
                        borderLeft={false}
                        severity={note.variant}
                        title={note.message}
                        sx={{ marginY: "8px" }}
                    />
                )
            })}
        </>
    )
}
