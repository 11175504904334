import { useMemo } from "react"
import { AvailabilityStatus, ErpInformation, ErpSystemConfig } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useErpConfig } from "@tm/utils"
import { styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export function Availabilities() {
    const { translateText } = useLocalization()
    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const AvailabilityComponent = useDefaultArticleItemState((x) => x.AvailabilityComponent)
    const { useOrderByDistributor } = useErpConfig()

    const Availability = useMemo(() => styled(AvailabilityComponent)({ "> div": { display: "block" } }), [AvailabilityComponent])
    const handleClickDetails = useDefaultArticleItemState((x) => x.handleClickDetails)

    function handleAvailabilityClick(useSpecialProcurement: boolean | undefined): void {
        if (useSpecialProcurement) {
            handleClickDetails("special-procurement", "#load-teccom")
        } else {
            handleClickDetails("stocks")
        }
    }

    const erpInfos = useMemo(() => {
        const list: {
            availability: ErpInformation["availability"]
            requestedQuantity?: number
            warehouses?: ErpInformation["warehouses"]
            tour?: ErpInformation["tour"]
            erpSystem?: ErpSystemConfig
        }[] = []
        const defaultErpInfo = articleErpInfos?.default?.state === "success" ? articleErpInfos.default : undefined
        if (defaultErpInfo) {
            list.push({ ...defaultErpInfo.response, erpSystem: defaultErpInfo.erpSystem })
        } else {
            list.push({ availability: { type: AvailabilityStatus.Unknown, shortDescription: translateText(12684) } })
        }
        const alternativeErpInfo = articleErpInfos?.alternative?.state === "success" ? articleErpInfos.alternative : undefined
        if (alternativeErpInfo) {
            list.push({ ...alternativeErpInfo.response, erpSystem: alternativeErpInfo.erpSystem })
        }
        return list
    }, [articleErpInfos, translateText])

    return (
        <>
            {erpInfos.map((info, index) => (
                <Availability
                    onClick={(useSpecialProcurement) => {
                        handleAvailabilityClick(useSpecialProcurement)
                    }}
                    key={`availability_${index}`}
                    {...info}
                    erpSystem={useOrderByDistributor ? info.erpSystem : undefined}
                />
            ))}
        </>
    )
}
