import * as Locales from "date-fns/locale"
import { Locale } from "date-fns"

export type LocalizationType = {
    locale: string
    region: string | null
}

export const getDateFnsLocale = ({ locale, region }: LocalizationType): Locale => {
    return (
        Object.entries(Locales).find((entry) => entry[0] === locale.substring(0, 2) + region || entry[0] === locale.substring(0, 2))?.[1] ||
        Locales.de
    )
    // return Locales[locale.substring(0, 2) + region] ?? Locales[locale.substring(0, 2)] ?? Locales.de
}
