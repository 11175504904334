import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { useMemo } from "react"
import { Box, CircularProgress, styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Tooltip } from "../../../generics/tooltip"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"

const Loader = styled(CircularProgress)({
    svg: { margin: 0 },
})

// Extended delivery also known as TecCom or Special Order
export function ExtendedDelivery() {
    const { translateText } = useLocalization()

    const { info, isLoading, load, showExtendedDeliveryInfo } = useDefaultArticleItemState((x) => x.extendedDeliveryInfo)
    const hasData = !!info

    const tooltipText = useMemo(() => concat(" ", translateText(1080), hasData && `(${translateText(276)})`), [hasData, translateText])

    if (!showExtendedDeliveryInfo || !hasData) {
        return null
    }

    return (
        <Box alignSelf="flex-end">
            <Tooltip title={tooltipText}>
                <Button
                    size="small"
                    startIcon={
                        isLoading ? (
                            <Loader size={12} color="inherit" />
                        ) : (
                            <Icon name={hasData ? "extended-delivery-request-confirmed" : "extended-delivery-request"} />
                        )
                    }
                    onClick={load}
                    disabled={isLoading}
                >
                    {translateText(13645)}
                </Button>
            </Tooltip>
        </Box>
    )
}
