import { AvailabilityStatus, RepairTimeProviderConfig } from ".."

export type TyresBundleParams = {
    articleListServiceUrlByQuery: string
    wheelsServiceURL: string
    awProviders: Array<RepairTimeProviderConfig>
    tyresCritsServiceUrl: string
    tyresServiceUrl: string
    vehicleRecordsEnabled?: boolean
    availabilityFilterItemsToShow?: AvailabilityStatus[]
    availabilityFilterItemsToShowSecondary?: AvailabilityStatus[]
    defaultSeason?: TyresSeason
    pageSize: number
    maxAutoRequest: number
    minAvailableItems: number
    addOns: TyresCarTypeAddon[]
    availabilityFilterDefault?: boolean
    traderReferenceId?: number
    isFiltersOnRightSide?: boolean
    useNewNavbar?: boolean
}

export type TyresCarTypeAddon = {
    Id: number,
    Description: string
    ShortCode: string
    Default: boolean
}

// preserve key order because is displayed on summary and filters
export enum ETyresSeason {
    default = "default",
    summer = "summer",
    allSeason = "allSeason",
    winter = "winter",
}

export type TyresSeason = `${ETyresSeason}`;

export enum ETyresCarType {
    "NONE",
    "PKW",
    "SUV",
    "LLKW",
    "LKW",
    "Motorrad",
    "Anhanger",
    "Agrar",
    "EM",
    "Industrie",
    "Militar",
}

// EFilterIds on API as string
export enum EFilterNames {
    // none = "none",
    carType = "carType",
    season = "season",
    manufacturer = "manufacturer",
    availability = "availability",
    noiseLevel = "noiseLevel",
    weight = "weight",
    wetGripClass = "wetGripClass",
    fuelEfficiency = "fuelEfficiency",
    externalRolling = "externalRolling",
    runExtraLoad = "runExtraLoad",
    width = "width",
    height = "height",
    inch = "inch",
    loadIndex = "loadIndex",
    speedIndex = "speedIndex",
    oeIdentifier = "oeIdentifier",
    tyreSize = "tyreSize",
    carTypeAndSeason = "carTypeAndSeason",
    extras = "extras",
    tyreModel = "tyreModel",
    tyreManufacturer = "tyreManufacturer",
    
    // TODO extra fields where not data but implemented ? 
    // silent = "silent",
    // studdable = "studdable",
    // studed = "studed",
};

export type FilterNames = `${EFilterNames}`;

export type TyreFilter = {
    value: string
    query: string
    sortPrio?: number
    info?: string
    group: FilterNames // EFilterIds as string
    groupId?: number // TMF filter id
    valueKey?: string // TODO find other way
    bitValue?: number // TODO find a way to get rid of this 
}
