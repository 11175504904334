import { createTheme, darken, getContrastRatio, lighten, alpha } from "@mui/material"
import { dark, light } from "@mui/material/styles/createPalette"
import {
    NextAnimation,
    NextBox,
    NextColors,
    NextFont,
    NextGeneral,
    NextLayout,
    NextLogin,
    NextOpacity,
    NextOverwrites,
    NextPageNavigation,
    NextRadius,
    NextTheme,
    StyleSizes,
} from "./themeTypes"
import { DEFAULT_THEME } from "./defaultTheme"

// https://mui.com/material-ui/customization/typography/

declare module "@mui/material/styles" {
    // Declare Custom theme Props
    interface Theme {
        name?: string
        colors?: NextColors
        general?: NextGeneral
        layout?: NextLayout
        login?: NextLogin
        opacity?: NextOpacity
        margin?: StyleSizes
        radius?: NextRadius
        box?: NextBox
        font?: NextFont
        animation?: NextAnimation
        pageNavigation?: NextPageNavigation
        overwrites?: NextOverwrites
    }

    // Declare Custom Fonts
    interface TypographyVariants {
        body3: React.CSSProperties
        label: React.CSSProperties
    }

    // Declare Custom Colors
    interface Palette {
        highlight: Palette["primary"]
        fallback: {
            highlightLight: string
        }
    }

    // # # # # # # # # # # # # # # # # # # #
    // allow configuration using `createTheme`
    // # # # # # # # # # # # # # # # # # # #
    interface ThemeOptions {
        name?: string
        colors?: NextColors
        general?: NextGeneral
        layout?: NextLayout
        login?: NextLogin
        opacity?: NextOpacity
        margin?: StyleSizes
        radius?: NextRadius
        box?: NextBox
        font?: NextFont
        animation?: NextAnimation
        pageNavigation?: NextPageNavigation
        overwrites?: NextOverwrites
    }

    interface PaletteOptions {
        highlight: PaletteOptions["primary"]
        fallback: {
            highlightLight: string
        }
    }

    interface TypographyVariantsOptions {
        body3?: React.CSSProperties
        label?: React.CSSProperties
    }

    interface BreakpointOverrides {
        xxl: true
    }
}

// Declare Custom Colors
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        highlight: true
    }
    interface ButtonPropsVariantOverrides {
        bordered: true
        textLight: true
    }
    interface ButtonPropsSizeOverrides {
        extralarge: true
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsSizeOverrides {
        extralarge: true
    }
}
declare module "@mui/material/InputBase" {
    interface InputBasePropsSizeOverrides {
        large: true
        extralarge: true
    }
}

// Declare Custom Fonts
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true
        label: true
        // disable default MUI Sizes
        h5: false
        h6: false
        subtitle1: false
        subtitle2: false
        caption: false
        overline: false
        button: false
    }
}

declare module "@mui/material/Alert" {
    interface AlertPropsVariantOverrides {
        standard: false
    }
}

declare module "@mui/material/TextField" {
    interface TextFieldPropsSizeOverrides {
        large: true
        extralarge: true
    }
}

declare module "@mui/material/FormControl" {
    interface FormControlPropsSizeOverrides {
        large: true
        extralarge: true
    }
}

declare module "@mui/material/InputBase" {
    interface InputBasePropsSizeOverrides {
        large: true
        extralarge: true
    }
}

const ENV = "dev"

export const getContrastText = (background: string) => {
    const contrastThreshold = 3 // default contrastThreshold
    const contrastText = getContrastRatio(background, dark.text.primary) >= contrastThreshold ? dark.text.primary : light.text.primary
    return contrastText
}

const getNextTheme = (catalogName: string): Promise<NextTheme | undefined> => {
    return fetch(`${ENV}/styles/${catalogName}/theme.json`)
        .then((response) => response.json())
        .then((data) => data as NextTheme)
        .catch((e) => {
            // eslint-disable-next-line no-console
            console.log("FetchError: ", e)
            return undefined
        })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTheme = async (catalogName: string) => {
    const nextTheme = await getNextTheme(catalogName)
    if (nextTheme) {
        return createNextMuiTheme(nextTheme)
    }
    return createTheme({ name: `unknownCatalogName: ${catalogName}` })
}

/** Override Mui Default Colors if included in next Theme */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createNextMuiTheme = (nextTheme: NextTheme) => {
    // Todo: need a better solution, quick fix for Sass-Loader json string
    // https://www.npmjs.com/package/node-sass-json-importer#importing-strings
    // TODO: remove this, we must exclude the json from the sass deployment
    const fontFamily = nextTheme.font?.fontFamily?.primary?.replaceAll("'", "") || DEFAULT_THEME.font?.fontFamily?.primary
    const buttonFontFamily = nextTheme.overwrites?.components?.button?.primary?.fontFamily?.replaceAll("'", "")
    const tabFontFamily = nextTheme.overwrites?.components?.tabs?.font?.fontFamily?.replaceAll("'", "")
    const borderRadius = nextTheme.radius?.default || DEFAULT_THEME.radius?.default

    return createTheme({
        name: nextTheme.name,
        palette: {
            primary: {
                /** generate Light, Dark and contrastText colors based on MainColor  */
                main: nextTheme.colors?.primary || DEFAULT_THEME.colors?.primary,
            },
            secondary: {
                main: nextTheme.colors?.secondary || DEFAULT_THEME.colors.secondary,
            },
            error: {
                main: nextTheme.colors?.danger || DEFAULT_THEME.colors.danger,
            },
            warning: {
                main: nextTheme.colors?.warning || DEFAULT_THEME.colors.warning,
            },
            info: {
                main: nextTheme.colors?.info || DEFAULT_THEME.colors?.info,
            },
            success: {
                main: nextTheme.colors?.success || DEFAULT_THEME.colors.success,
            },
            highlight: {
                main: nextTheme.colors?.highlight || DEFAULT_THEME.colors.highlight,
                contrastText: getContrastText(nextTheme.colors?.highlight || DEFAULT_THEME.colors.highlight),
                light: lighten(nextTheme.colors?.highlight || DEFAULT_THEME.colors.highlight, 0.68), // on default like #fbe3b2;
                dark: darken(nextTheme.colors?.highlight || DEFAULT_THEME.colors.highlight, 0.4),
            },
            background: {
                default: nextTheme.colors?.background || DEFAULT_THEME.colors.background,
                paper: nextTheme.colors?.paperBackground || DEFAULT_THEME.colors.background,
            },
            text: {
                primary: nextTheme.colors?.text || DEFAULT_THEME.colors.text,
            },
            fallback: {
                highlightLight: "#c8c1c2",
            },
        },
        shape: {
            borderRadius: borderRadius ? parseInt(borderRadius) : undefined,
        },
        typography: {
            fontFamily,
            fontSize: 16,
            htmlFontSize: 16,
            h5: undefined,
            h6: undefined,
            subtitle1: undefined,
            subtitle2: undefined,
            caption: undefined,
            overline: undefined,
            button: undefined,
            h1: {
                ...DEFAULT_THEME.font?.h1,
                ...nextTheme.font?.h1,
                opacity: nextTheme?.opacity?.primary,
            },
            h2: {
                ...DEFAULT_THEME.font?.h2,
                ...nextTheme.font?.h2,
                opacity: nextTheme?.opacity?.primary,
            },
            h3: {
                ...DEFAULT_THEME.font?.h3,
                ...nextTheme.font?.h3,
                opacity: nextTheme?.opacity?.primary,
            },
            h4: {
                ...DEFAULT_THEME.font?.h4,
                ...nextTheme.font?.h4,
                opacity: nextTheme?.opacity?.primary,
            },
            body1: {
                ...DEFAULT_THEME.font?.body1,
                ...nextTheme.font?.body1,
                opacity: nextTheme?.opacity?.primary,
            },
            body2: {
                ...DEFAULT_THEME.font?.body2,
                ...nextTheme.font?.body2,
                opacity: nextTheme?.opacity?.primary,
            },
            body3: {
                ...DEFAULT_THEME.font?.body3,
                ...nextTheme.font?.body3,
                opacity: nextTheme?.opacity?.primary,
            },
            label: {
                ...DEFAULT_THEME.font?.label,
                ...nextTheme.font?.label,
                opacity: nextTheme?.opacity?.primary,
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 640,
                md: 1024,
                lg: 1280,
                xl: 1680,
                xxl: 1996,
            },
        },
        colors: nextTheme?.colors,
        general: nextTheme?.general,
        layout: nextTheme?.layout,
        login: nextTheme?.login,
        opacity: nextTheme?.opacity,
        pageNavigation: nextTheme?.pageNavigation,
        radius: nextTheme?.radius,
        margin: nextTheme?.margin,
        box: nextTheme?.box,
        font: {
            ...DEFAULT_THEME.font,
            ...nextTheme?.font,
        },
        animation: nextTheme?.animation,
        overwrites: {
            ...nextTheme.overwrites,
            components: {
                ...nextTheme.overwrites?.components,
                partlist: {
                    ...nextTheme.overwrites?.components?.partlist,
                    groupHeader: {
                        // Use for all Catalogs the monochrom as default
                        monochrom: true,
                        ...nextTheme.overwrites?.components?.partlist?.groupHeader,
                    },
                },
                button: {
                    ...nextTheme.overwrites?.components?.button,
                    primary: {
                        ...nextTheme.overwrites?.components?.button?.primary,
                        fontFamily: buttonFontFamily,
                    },
                },
                tabs: {
                    ...nextTheme.overwrites?.components?.tabs,
                    font: {
                        ...nextTheme.overwrites?.components?.tabs?.font,
                        fontFamily: tabFontFamily,
                    },
                },
                basketSummary: {
                    ...nextTheme.overwrites?.components?.basketSummary,
                    component: {
                        backgroundColor: nextTheme.colors.highlight,
                        ...nextTheme.overwrites?.components?.basketSummary?.component,
                    },
                },
            },
        },
        components: {
            MuiTextField: {
                variants: [
                    {
                        props: { size: "extralarge" },
                        style: {},
                    },
                ],
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        lineHeight: "1.2",
                    },
                    sizeSmall: {
                        fontSize: "0.875rem",
                        padding: "0.3em",
                    },
                    sizeMedium: {
                        fontSize: "1rem",
                        padding: "6px",
                    },
                    sizeLarge: {
                        fontSize: "1.125rem",
                        padding: "0.5em",
                    },
                },
                variants: [
                    {
                        props: { variant: "bordered", size: "extralarge" },
                        style: {
                            [`&::before`]: {
                                content: "''",
                                display: "flex",
                                width: "100%",
                                height: "3px",
                                position: "absolute",
                                bottom: "0",
                            },
                        },
                    },
                ],
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: alpha(nextTheme.colors.dark || DEFAULT_THEME.colors.dark, nextTheme.opacity?.primary || DEFAULT_THEME.opacity.primary),
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    sizeSmall: {
                        fontSize: "0.8rem",
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    standard: {
                        padding: "0.346em 0.5em 0.346em 2em",
                    },
                    outlined: {
                        padding: "5px 0.5em 5px 2em",
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popper: {
                        backgroundColor: lighten(nextTheme.colors.primary, 1),
                    },
                },
            },
        },
    })
}
