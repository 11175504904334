import { ajax, getStoredAuthorization } from "@tm/utils";
import { HourlyRate, UpdateRepairShopRequest, RepairTimeOptions, OrderOptions, DirectBuyOptions, ShowAllOptionsRequest, ShowAllOptionsResponse, RepairShop, ChangeOrderOptionsRequest } from "../model";
import { mapRepairShop } from "./mapper";

export function showAllOptions(serviceUrl: string, body: ShowAllOptionsRequest) {
    const url = `${serviceUrl}/ShowAllOptions`
    const authorization = getStoredAuthorization()

    return ajax<ShowAllOptionsResponse>({ url, body, authorization })
        .then((response): ShowAllOptionsResponse => ({
            ...response,
            repairShop: mapRepairShop(response?.repairShop),
        }))
}

export function showOrderOptions(serviceUrl: string) {
    const url = `${serviceUrl}/ShowOrderOptions`
    const authorization = getStoredAuthorization()

    return ajax<OrderOptions>({ url, authorization })
}

export function changeLogo(serviceUrl: string, logoBytes: Array<number>) {
    const url = `${serviceUrl}/ChangeLogo`
    const authorization = getStoredAuthorization()
    const body = { logoBytes }

    return ajax({ url, body, authorization, method: "POST" })
}

export function changePrintLogo(serviceUrl: string, printLogo: boolean) {
    const url = `${serviceUrl}/ChangePrintLogo`
    const authorization = getStoredAuthorization()
    const body = { printLogo }

    return ajax({ url, body, authorization, method: "POST" })
}

export function removeLogo(serviceUrl: string) {
    const url = `${serviceUrl}/RemoveLogo`
    const authorization = getStoredAuthorization()
    const body = {}

    return ajax({ url, body, authorization, method: "POST" })
}

export function changeHourlyRates(serviceUrl: string, hourlyRates: Array<HourlyRate>, currencyCode: string) {
    const url = `${serviceUrl}/ChangeHourlyRates`
    const authorization = getStoredAuthorization()
    const body = { hourlyRates, currencyCode }

    return ajax({ url, body, authorization, method: "POST" })
}

export function updateRepairShop(serviceUrl: string, body: UpdateRepairShopRequest) {
    const url = `${serviceUrl}/UpdateRepairShop`
    const authorization = getStoredAuthorization()

    return ajax<RepairShop>({ url, body, authorization, method: "POST" })
        .then(mapRepairShop)
}

export function changeRepairTimeOptions(serviceUrl: string, body: RepairTimeOptions) {
    const url = `${serviceUrl}/ChangeRepairTimeOptions`
    const authorization = getStoredAuthorization()

    return ajax<RepairTimeOptions>({ url, authorization, body, method: "POST" })
}

export function changeOrderOptions(serviceUrl: string, body: ChangeOrderOptionsRequest) {
    const url = `${serviceUrl}/ChangeOrderOptions`
    const authorization = getStoredAuthorization()

    return ajax({ url, authorization, body, method: "POST" })
}

export function changeDirectBuyOptions(serviceUrl: string, body: DirectBuyOptions) {
    const url = `${serviceUrl}/ChangeDirectBuyOptions`
    const authorization = getStoredAuthorization()

    return ajax<DirectBuyOptions>({ url, authorization, body, method: "POST" })
}