import { useRef, useState } from "react"
import { styled, tooltipClasses } from "@mui/material"
import { ImageViewer as ArticleImageViewer } from "./ImageViewer"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Tooltip } from "../../generics/tooltip"
import { Image as ComponentsImage } from "../../generics/image"
import { ImageViewer } from "../imageViewer"
import { ImageContainer } from "./ImageContainer"

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "60vw",
    },
}))

export function Image() {
    const handleClickImage = useDefaultArticleItemState((x) => x.handleClickImage)
    const handleCloseImage = useDefaultArticleItemState((x) => x.handleCloseImage)
    const showImageViewer = useDefaultArticleItemState((x) => x.showImageViewer)
    const article = useDefaultArticleItemState((x) => x.article)
    const {
        thumbnailUrl,
        thumbnailDescription,
        thumbnailFallbackUrl,
        articleImages,
        startIndex,
        enableLargeView,
        customThumbnailComponent,
        loading,
        onLoadImages,
    } = useDefaultArticleItemState((x) => x.displayArticle.image)

    const [useFallbackThumbnail, setUseFallbackThumbnail] = useState(false)
    const [open, setOpen] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const showArticleImages = useDefaultArticleItemState((x) => x.options.showArticleImages)

    if (!showArticleImages) {
        return null
    }

    const handleThumbnailLoadError = () => {
        if (thumbnailFallbackUrl) {
            setUseFallbackThumbnail(true)
        }
    }

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        if (onLoadImages) {
            onLoadImages()
        }
        setOpen(true)
    }

    const renderImages = () => {
        return <ImageViewer images={articleImages ?? []} onClose={handleTooltipClose} startIndex={startIndex} isLoading={loading ?? false} />
    }

    let content = (
        <ImageContainer data-clickable ref={containerRef}>
            <ComponentsImage
                type="article"
                src={!useFallbackThumbnail ? thumbnailUrl : thumbnailFallbackUrl ?? ""}
                title={thumbnailDescription}
                onClick={handleClickImage}
                onError={handleThumbnailLoadError}
                loading="lazy"
                height="60px"
                width="60px"
            />
            {customThumbnailComponent}
        </ImageContainer>
    )

    if (enableLargeView) {
        content = (
            <StyledTooltip
                color="primary"
                variant="light"
                open={open}
                placement="right"
                title={renderImages()}
                disableHoverListener
                onClick={handleTooltipOpen}
                onClickAway={handleTooltipClose}
            >
                {content}
            </StyledTooltip>
        )
    }

    return (
        <div>
            {content}
            {showImageViewer && <ArticleImageViewer article={article} anchorEl={containerRef.current} onClose={handleCloseImage} />}
        </div>
    )
}
