import { useLocalization } from "@tm/localization"
import { AttributeValueParms, ModificationState } from "@tm/models"
import { useMemo } from "react"
import { Stack, StackProps, styled } from "@mui/material"
import { getContrastText } from "../../../theme"
import { Typography } from "../../../generics/typographie"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"

type AttributeValueElementProps = StackProps & {
    isClickable?: boolean
    isSelected?: boolean
    isRemoved?: boolean
    isHighlighted?: boolean
}

const AttributeValueElement = styled(Stack, {
    shouldForwardProp: (prop: string) => !["isClickable", "isSelected", "isRemoved", "isHighlighted"].includes(prop),
})<AttributeValueElementProps>(({ theme, isClickable, isSelected, isRemoved, isHighlighted }) => ({
    fontWeight: 600,
    opacity: 1,
    padding: "2px 4px",
    border: "1px solid transparent",
    borderRadius: theme.radius?.default,
    fontFamily: "Roboto Condensed", // theme.font?.fontFamily?.secondary,
    whiteSpace: "nowrap",

    svg: {
        marginRight: 4,
    },

    ...(isRemoved && {
        textDecoration: "line-through",
    }),
    ...(isClickable && {
        cursor: "pointer",
        ...(!isSelected && {
            "&:hover *": {
                color: theme.palette.primary.main,
            },
        }),
    }),
    ...(isHighlighted && {
        backgroundColor: theme.palette.highlight.light,
        borderColor: theme.palette.highlight.main,
    }),
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        borderColor: "transparent",
        "*": {
            color: getContrastText(theme.palette.primary.main),
        },
    }),
}))
AttributeValueElement.defaultProps = { direction: "row" }

type AttributeValueProps = {
    valueData: AttributeValueParms
    onClick?(val: string): void
}

export function AttributeValue({ valueData, onClick }: AttributeValueProps) {
    const { translateText } = useLocalization()

    const handleClick = useMemo(
        () => (onClick && valueData.value ? () => valueData.value && onClick(valueData.value) : undefined),
        [valueData.value, onClick]
    )

    const isRemoved = valueData.modificationState === ModificationState.Removed

    let content = <Typography variant="label">{valueData.displayValue}</Typography>

    if (valueData.alternatives.length) {
        content = (
            <Tooltip
                sx={{ whiteSpace: "pre-wrap" }}
                title={`${translateText(valueData.isOptimized ? 1642 : 1643)}:\n${valueData.alternatives.join("\n")}`}
            >
                {content}
            </Tooltip>
        )
    }

    return (
        <AttributeValueElement
            onClick={handleClick}
            isHighlighted={valueData.isHighlighted}
            isSelected={valueData.isSelected}
            isRemoved={isRemoved}
            isClickable={!!onClick && !isRemoved}
        >
            {(() => {
                switch (valueData.modificationState) {
                    case ModificationState.Added:
                        return (
                            <Tooltip title={translateText(886)}>
                                <Icon name="plus" size="12px" />
                            </Tooltip>
                        )
                    case ModificationState.Removed:
                        return (
                            <Tooltip title={translateText(895)}>
                                <Icon name="minus" size="12px" />
                            </Tooltip>
                        )
                    default:
                }
            })()}
            {content}
        </AttributeValueElement>
    )
}
