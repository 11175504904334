import { CSSProperties, Children, Component, MouseEvent, ReactChild, cloneElement } from "react"
import * as ReactDOM from "react-dom"
import { equals, registerOutsideClick } from "@tm/utils"
import { Size } from "../../models/skins"
import { bindMethodsToContext } from "../../helper"
import { Text } from "../"
import cx from "bem-classnames"

export type TooltipPositions = "top" | "right" | "bottom" | "left"
export type TooltipEvents = "hover" | "click"
export type TooltipStyle = "dark" | "light" | "primary" | "highlight" | "highlight-filled"

export type Props = {
    content?: string | JSX.Element
    textSize?: Size
    position?: TooltipPositions
    event?: TooltipEvents
    hideDelay?: number
    style?: TooltipStyle
    hideOnOutsideClick?: boolean
    resizable?: boolean
    disabled?: boolean
    className?: string
    children?: ReactChild
    showOnlyOnOverflow?: boolean
    onChangeVisibility?(open: boolean): void
    preventCloseOnScroll?: boolean
    forcePosition?: boolean
}

export type State = {
    isVisible: boolean
    position: TooltipPositions
}

const bemConfig = {
    name: "tooltip",
    modifiers: ["style"],

    wrapper: {
        name: "tooltip__wrapper",
        modifiers: ["position"],
        states: ["visible"],
    },

    arrow: {
        name: "tooltip__arrow",
    },

    content: {
        name: "tooltip__content",
        states: ["resizable"],
    },
}


export default class Tooltip extends Component<Props, State> {
    private tooltipRef: HTMLElement | null = null
    private wrapperRef: HTMLElement | null = null
    private contentRef: HTMLElement | null = null

    private childRef: HTMLElement | null = null
    private tooltipsRef: HTMLElement | null = document.getElementById("tooltips")
    private removeOutsideClick?: () => void

    private showTooltipTimeout: number

    static get defaultProps(): Partial<Props> {
        return {
            position: "bottom",
            event: "hover",
            hideDelay: 0,
            style: "dark",
            hideOnOutsideClick: true,
            resizable: false,
        }
    }

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)

        this.tooltipsRef = document.getElementById("tooltips")

        this.state = {
            isVisible: false,
            position: this.props.position!
        }
    }

    componentDidMount() {
        if (window.addEventListener) {
            if(!this.props.preventCloseOnScroll) {
                window.addEventListener("wheel", this.scrollEventHandler)
            }
            this.addEventsToRef()
        }
    }

    scrollEventHandler = () => {
        if (this.state.isVisible) {
            this.handleHideTooltip()
        }
    }

    addEventsToRef = () => {
        if (this.childRef) {
            const { event } = this.props
            const handlers = this.getChildHandler(event)

            for (var key in handlers) {
                if (handlers.hasOwnProperty(key)) {
                    this.childRef.addEventListener(key, (handlers as any)[key])
                }
            }
        }
    }

    removeEventsFromRef = () => {
        if (this.childRef) {
            const { event } = this.props
            const handlers = this.getChildHandler(event)

            for (var key in handlers) {
                if (handlers.hasOwnProperty(key)) {
                    this.childRef.removeEventListener(key, (handlers as any)[key])
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.removeOutsideClick) {
            this.removeOutsideClick()
            this.removeOutsideClick = undefined
        }

        window.removeEventListener("wheel", this.scrollEventHandler)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.position != this.state.position) {
            this.setState({
                position: nextProps.position!
            })
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // if tooltip was hidden clear resized styles
        if (prevState.isVisible && !this.state.isVisible) {
            this.resetContentSize()
        }

        // handle offscreen if tooltip was shown or got different content
        if ((!prevState.isVisible || !equals(prevProps.content, this.props.content)) && this.state.isVisible) {
            this.handleOffscreen()
        }
    }


    private resetContentSize() {
        if (!this.contentRef) return

        this.contentRef.removeAttribute("style")
    }


    private handleOffscreen() {
        if (!this.wrapperRef) return

        const { position} = this.state
        const {forcePosition} = this.props

        const el = this.wrapperRef
        el.removeAttribute("style")
        const rect = el.getBoundingClientRect()
        const parent = this.getFirstOverflowParent(el) || document.body
        const parentRect = parent.getBoundingClientRect()

        // This value will be added to the overflows,
        // to have a little gap bewteen the tooltip and the
        // the overflowing parent or the document body
        const gap = 2

        const overflows = {
            top: -(rect.top - parentRect.top) + gap,
            right: rect.right - parentRect.right + gap,
            bottom: rect.bottom - parentRect.bottom + gap,
            left: -(rect.left - parentRect.left) + gap,
        }

        // move tooltip to opposite position if offscreen
        if(!forcePosition) {
            switch (position) {
                case "top":
                    overflows.top += window.pageYOffset
                    if (overflows.top > 0) this.setState({ position: "bottom" })
                    break
                case "right":
                    if (overflows.right > 0) this.setState({ position: "left" })
                    break
                case "bottom":
                    overflows.bottom -= window.pageYOffset
                    if (overflows.bottom > 0) this.setState({ position: "top" })
                    break
                case "left":
                    if (overflows.left > 0) this.setState({ position: "right" })
                    break
            }
        }

        const arrow = el.firstElementChild as HTMLElement
        arrow.removeAttribute("style")
        const arrowRect = arrow.getBoundingClientRect()

        let offset: number | undefined = undefined
        let arrowOffset: number | undefined = undefined

        // move tooltip horizontally if positioned top or bottom and offscreen
        // or vertically if position right or left and offscreen
        if (position == "top" || position == "bottom") {
            const halfWrapperWidth = rect.width / 2
            const halfArrowWidth = arrowRect.width / 2

            if (overflows.right > 0) {
                offset = -halfWrapperWidth - overflows.right
                arrowOffset = Math.min(-halfArrowWidth + overflows.right, halfWrapperWidth - arrowRect.width)
            }
            else if (overflows.left > 0) {
                offset = -halfWrapperWidth + overflows.left
                arrowOffset = Math.max(-halfArrowWidth - overflows.left, -halfWrapperWidth)
            }

            if (offset != undefined) el.style.transform = `translateX(${offset}px)`
            if (arrowOffset != undefined) arrow.style.transform = `translateX(${arrowOffset}px)`
        }
        else if (position == "right" || position == "left") {
            const halfWrapperHeight = rect.height / 2
            const halfArrowHeight = arrowRect.height / 2

            if (overflows.top > 0) {
                offset = -halfWrapperHeight + overflows.top
                arrowOffset = Math.max(-halfArrowHeight - overflows.top, -halfWrapperHeight)
            }
            else if (overflows.bottom > 0) {
                offset = -halfWrapperHeight - overflows.bottom
                arrowOffset = Math.min(-halfArrowHeight + overflows.bottom, halfWrapperHeight - arrowRect.height)
            }

            if (offset != undefined) el.style.transform = `translateY(${offset}px)`
            if (arrowOffset != undefined) arrow.style.transform = `translateY(${arrowOffset}px)`
        }
    }

    private getFirstOverflowParent(el: HTMLElement): HTMLElement | undefined {
        if (!el || !el.parentElement) return

        const { parentElement } = el
        const style = window.getComputedStyle(parentElement)

        if (style.overflowX == "hidden" ||
            style.overflowY == "hidden") {
            return parentElement
        }

        return this.getFirstOverflowParent(parentElement)
    }

    private handleTooltipRef(ref: HTMLElement | null) {
        this.tooltipRef = ref
    }

    private handleWrapperRef(ref: HTMLElement | null) {
        this.wrapperRef = ref

        this.registerOutsideClick()
    }

    private handleContentRef(ref: HTMLElement | null) {
        this.contentRef = ref
    }

    private handleChildRef(ref: HTMLElement | null) {
        this.childRef = ref as HTMLElement
    }

    /*
    * Show/Hide the tooltip
    * return void
    */
    private handleToggleTooltip(e: MouseEvent<HTMLElement>) {
        // e.stopPropagation()

        // ignore clicks inside the tooltip content
        if (this.wrapperRef && this.wrapperRef.contains(e.target as Node)) return

        if (this.state.isVisible) {
            this.handleHideTooltip()
        }
        else {
            this.handleShowTooltip()
        }
    }

    private handleShowTooltipDelayed(e?: MouseEvent<HTMLElement>) {
        this.showTooltipTimeout = window.setTimeout(() => {
            this.handleShowTooltip(e)
        }, 500)
    }

    /*
    * Change the state to show the tooltip
    * @return void
    */
    private handleShowTooltip(e?: MouseEvent<HTMLElement>) {
        const { disabled, onChangeVisibility, showOnlyOnOverflow } = this.props

        if (disabled) {
            return
        }

        if (showOnlyOnOverflow && e) {
            if (e.currentTarget.offsetWidth > e.currentTarget.scrollWidth + 1) return
        }

        this.setState({ isVisible: true })

        this.registerOutsideClick()

        onChangeVisibility && onChangeVisibility(true)
    }

    /**
     * registering outside click in a timeout to prevent the outsideclick to be triggered by the body
     */
    registerOutsideClick = () => {
        setTimeout(() => {
            if (!this.removeOutsideClick && this.wrapperRef && this.props.hideOnOutsideClick) {
                this.removeOutsideClick = registerOutsideClick(this.wrapperRef, this.handleHideTooltip)
            }
        }, 0)
    }

    /*
    * Change the state to hide the tooltip
    * @return void
    */
    private handleHideTooltip() {
        const hide = () => {
            window.clearTimeout(this.showTooltipTimeout)

            this.setState({
                isVisible: false,
                position: this.props.position!
            })

            if (this.wrapperRef) {
                this.wrapperRef.style.transform = "";
                (this.wrapperRef.firstElementChild as HTMLElement).style.transform = ""
            }

            if (this.removeOutsideClick && this.props.hideOnOutsideClick) {
                this.removeOutsideClick()
                this.removeOutsideClick = undefined
            }

            const { onChangeVisibility } = this.props
            onChangeVisibility && onChangeVisibility(false)
        }

        const { hideDelay } = this.props

        if (hideDelay && hideDelay > 0) {
            window.setTimeout(() => hide(), hideDelay)
        }
        else {
            hide()
        }
    }

    show() {
        this.handleShowTooltip()
    }

    hide() {
        this.handleHideTooltip()
    }

    private renderContent() {
        const { content, style, textSize } = this.props

        if (typeof (content) == "string") {
            return (
                <Text
                    size={textSize}
                    modifiers={style == "dark" ? "light" : undefined}
                >
                    {content}
                </Text>
            )
        }

        return content
    }

    private renderWrapper() {
        const { resizable, content } = this.props
        const { position } = this.state

        if (!content) return

        return (
            <div className={cx(bemConfig.wrapper, { position, visible: this.state.isVisible })} ref={this.handleWrapperRef}>
                <div className={cx(bemConfig.arrow)} />
                <div className={cx(bemConfig.content, { resizable })} ref={this.handleContentRef}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    private renderToolTipWrapper() {
        const { style, className } = this.props

        const wrapperPositioning = this.getWrapperBoundingRect()

        if (!wrapperPositioning || !this.tooltipsRef) { return null }

        const portal = ReactDOM.createPortal(
            <div className={cx(bemConfig, { style }, className)} ref={this.handleTooltipRef} style={wrapperPositioning} >
                {this.renderWrapper()}
            </div>,
            this.tooltipsRef
        )

        return portal
    }


    private getWrapperBoundingRect = () => {
        let boundingRect: DOMRect | null = null
        const child = this.childRef
        if (child && typeof (child) !== "string" && typeof (child) !== "function" && child) {
            boundingRect = child.getBoundingClientRect() as DOMRect
        }

        if (!boundingRect) { return null }
        const { position } = this.state

        const { height, top, left, x: xbr, y: ybr } = boundingRect
        const y = ybr || top
        const x = xbr || left

        const positions = {
            top: {
                top: y + window.pageYOffset,
                left: x
            },
            left: {
                top: y + (height / 2),
                left: x
            },
            bottom: {
                top: y + height + window.pageYOffset,
                left: x
            },
            right: {
                top: y + (height / 2) + window.pageYOffset,
                left: x
            }
        }

        const wrapperPositioning: CSSProperties = {
            minWidth: boundingRect.width,
            height: 0, //boundingRect.height,
            position: "absolute",
            ...positions[position || "top"]
        }

        return wrapperPositioning
    }

    getChildHandler(event?: "hover" | "click") {
        return event == "hover" ? {
            mouseenter: this.handleShowTooltipDelayed,
            mouseleave: this.handleHideTooltip
        } : {
                click: this.handleToggleTooltip
            }
    }

    render() {
        cx.prefixes.states = "is-"
        const { event, children } = this.props
        let childrenWithRefAndHandlers = children

        if (children) {
            let content: any | null = null
            switch (typeof (children)) {
                case "string":
                case "number": {
                    content = <div>{children}</div>
                    break;
                }

                default: {
                    content = children
                    break;
                }
            }

            (childrenWithRefAndHandlers as any) = Children.map(content, (child) => {
                return cloneElement(
                    content,
                    {
                        ref: (ref: HTMLElement | null) => {
                            let hoverableRef = ref

                            if (hoverableRef && !hoverableRef.getBoundingClientRect) {
                                hoverableRef = ReactDOM.findDOMNode(hoverableRef) as any
                            }
                            this.handleChildRef(hoverableRef)

                            // Call the original ref, if any
                            const originRefHandler = child.ref;
                            if (typeof originRefHandler === 'function') {
                                originRefHandler(hoverableRef);
                            }
                        }
                    }
                )
            })

        }

        return event ? (
            <>
                {this.state.isVisible && this.renderToolTipWrapper()}
                {childrenWithRefAndHandlers}
            </>
        ) : null
    }
}
