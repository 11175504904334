import { forwardRef, useMemo } from "react"
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Locale } from "date-fns"

import { FilledTextFieldProps, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { TextField, TextFieldProps } from "../../textfield"
import { Icon } from "../../Icons"
import { getDateFnsLocale, LocalizationType } from "../helper/locale"

export type DatePickerProps = Omit<MuiDatePickerProps<Date, Date>, "renderInput"> & {
    localisation: LocalizationType
    textfieldProps?: TextFieldProps
    size?: "small" | "medium" | "large" | "extralarge"
}

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(({ localisation, textfieldProps = { sx: { width: 180 } }, ...rest }, ref) => {
    const { translateText } = useLocalization()
    const localLanguage = useMemo((): Locale => {
        return getDateFnsLocale(localisation)
    }, [localisation])

    const dateMask = useMemo((): { inputFormat: string; mask: string } => {
        /* use this to see how you should configure the date https://date-fns.org/v2.25.0/docs/format */
        switch (localLanguage.code) {
            case "de":
            case "it": {
                return {
                    inputFormat: "dd.MM.yyyy",
                    mask: "__.__.____",
                }
            }

            default: {
                return {
                    inputFormat: "yyyy/MM/dd",
                    mask: "____/__/__",
                }
            }
        }
    }, [localLanguage.code])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localLanguage} localeText={{ todayButtonLabel: translateText(142) }}>
            <MuiDatePicker
                inputFormat={dateMask.inputFormat}
                mask={dateMask.mask}
                ref={ref}
                disableMaskedInput={false}
                renderInput={(params) => {
                    const castedParams = { ...params, size: rest.size } as FilledTextFieldProps
                    return <StyledTextField {...textfieldProps} {...castedParams} />
                }}
                components={{
                    OpenPickerIcon: (props) => <Icon name="calendar" height="24px" {...props} />,
                    ...(rest.components || {}),
                }}
                componentsProps={{
                    actionBar: {
                        actions: ["today"],
                    },
                    ...rest.componentsProps,
                }}
                views={["year", "month", "day"]}
                {...rest}
            />
        </LocalizationProvider>
    )
})

const StyledTextField = styled(TextField)({
    ".MuiInputAdornment-positionEnd": {
        margin: 0,
    },
    ".inputButtons": {
        padding: 0,
    },
})
