import { Box, Typography, styled, Tooltip } from "@mui/material"
import { TreeNode as TreeNodeModel, VehicleType } from "@tm/models"
import { getUIA } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Stack } from "@mui/system"
import { Icon } from "../../generics/Icons"
import { SearchtreeIcon } from "./SearchtreeButton/SearchtreeIcon"

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "16px",
    "div:nth-child(2)": {
        borderRadius: "2px",
        ":hover": {
            background: theme.palette.grey[200],
            div: { color: theme.palette.primary.main },
        },
    },
}))

const HoverWrapper = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    cursor: "pointer",
    ":hover": {
        svg: { fill: theme.palette.highlight.light },
    },
}))

const ItemText = styled(Typography<"div">)({
    padding: `1px 8px`,
    justifyContent: "flex-start",
})

const ClickIcon = styled(Icon)(({ theme }) => ({
    fill: theme.palette.grey[600],
    marginLeft: "8px",
    marginRight: "8px",
    cursor: "pointer",
}))

const IconWrapper = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
}))

type Props = {
    node: TreeNodeModel
    hideIcon?: boolean
    onClick: (node: TreeNodeModel, fastClick?: boolean) => void
    nodeVariant?: "fastClick" | "default"
}

export function TreeNode(props: Props) {
    const { translateText } = useLocalization()
    const { node } = props
    const enterDelay = 500
    const iconNameSplits = props.node.thumbnailUrl?.match("[0-9]*_[0-9]*")
    let iconName = iconNameSplits?.[0] ?? props?.node.thumbnailUrl

    if (node.vehicleType === VehicleType.Motorcycle) {
        switch (node.id) {
            case 2099699:
                iconName = "bike-drive-system"
                break
            case 2100194:
                iconName = "bike-steering"
                break
            case 2102819:
            case 2103053:
                iconName = "bike-body-parts"
                break
            case 2103673:
                iconName = "bike-accessories"
                break
            case 2098259:
                iconName = "bike-shock-absorber"
                break
            default:
        }
    }

    const showSecondLevelIcon =
        !props.node.thumbnailUrl ||
        props.node.vehicleType === VehicleType.CommercialVehicle ||
        props.node.vehicleType === VehicleType.LightCommercialVehicle ||
        props.hideIcon

    if (props.nodeVariant === "fastClick") {
        return (
            <Stack width="100%">
                <Box
                    onClick={() => props.onClick(props.node)}
                    sx={{ display: "flex", justifyContent: "center", overflow: "hidden" }}
                    {...getUIA("SearchtreeV2TreeNodeName", props.node.id)}
                >
                    <HoverWrapper>
                        <Icon size="36px" name={iconName} color="highlight" />
                        <ItemText
                            variant="label"
                            component="div"
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "215px" }}
                        >
                            {props.node.name}
                        </ItemText>
                    </HoverWrapper>
                </Box>
            </Stack>
        )
    }

    return (
        <Wrapper>
            <Tooltip
                title={translateText(13625)}
                placement="left-start"
                enterDelay={enterDelay}
                enterNextDelay={enterDelay}
                arrow
                sx={{ fontFamily: ["RobotoCondensed-Regular"] }}
            >
                <Box
                    onClick={() => props.onClick(props.node, true)}
                    sx={{ cursor: "pointer", marginRight: "6px" }}
                    {...getUIA("SearchtreeV2TreeNodeIcon")}
                >
                    <SearchtreeIcon iconName={iconName} isSecondLevel={showSecondLevelIcon} />
                </Box>
            </Tooltip>
            <Tooltip
                title={translateText(13626)}
                placement="right-start"
                enterDelay={enterDelay}
                enterNextDelay={enterDelay}
                arrow
                sx={{ fontFamily: ["RobotoCondensed-Regular"] }}
            >
                <IconWrapper
                    onClick={() => props.onClick(props.node)}
                    sx={{ overflow: "hidden" }}
                    {...getUIA("SearchtreeV2TreeNodeName", props.node.id)}
                >
                    <ItemText
                        variant="body1"
                        component="div"
                        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "215px" }}
                    >
                        {props.node.name}
                    </ItemText>
                    {props.node.hasChildNodes && <ClickIcon name="down" width="14px" height="14px" />}
                </IconWrapper>
            </Tooltip>
        </Wrapper>
    )
}
