import { Article, OE } from "../article"

export enum EReplaceButtonBundle {
    DMGDat = "dmg-dat",
    FastService = "fast-service",
    FastCalculatorNext = "fast-calculator-next",
}

export type ReplaceButtonBundle = `${EReplaceButtonBundle}`

export type ReplaceButtonPayload = {
    bundle: ReplaceButtonBundle
    part: Article | OE.OePart
    isNewList?: boolean
}