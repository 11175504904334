import { ajax } from "@tm/utils"
import { DEFAULT_THEME, NextTheme } from "@tm/components"

export * from "./ThemeProvider"
export * from "./ThemeProviderContext"
export * from "./withTheme"


export const THEME_IDENTIFIER = "__NEXT_STYLETHEME__"
let _theme: NextTheme

/**
 * @deprecated Please use properties from the MUI theme instead by using `styled` from `@tm/components` or the `sx` property existing for MUI components.
 */
export function getStyleTheme(): NextTheme {
    if (typeof window != "undefined") {
        return (window as any)[THEME_IDENTIFIER]
    }
    return _theme
}

export function loadStyleTheme(url: string) {
    return ajax({ url }).then(json => typeof json == "object" && setStyleTheme(json))
}

export function setStyleTheme(theme: NextTheme) {
    theme = {
        ...DEFAULT_THEME,
        ...theme,
        font: {
            ...DEFAULT_THEME.font,
            ...theme?.font,
        }
    }
    if (typeof window !== "undefined") {
        (window as any)[THEME_IDENTIFIER] = theme
    }
    _theme = theme
}
