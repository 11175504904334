import { Box } from "@mui/material"
import { ErpPrice, MemoType } from "@tm/models"
import { concat } from "@tm/utils"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"
import { TooltipPrice } from "./components/TooltipPrice"

type Props = {
    price: ErpPrice
    additionalInfoIconSize?: string | number
}

export function ErpPriceAdditionalInfo({ price, additionalInfoIconSize = 16 }: Props) {
    function renderAdditionalDescriptions() {
        const additionalDescriptions = price.memos?.filter((x) => x.type === MemoType.AdditionalDescription) ?? []

        if (!additionalDescriptions.length) {
            return
        }

        return <Box>{`${concat("\n", ...additionalDescriptions.map((x) => x.text))}\n`}</Box>
    }

    function renderTooltipContent() {
        return (
            <Box sx={{ whiteSpace: "pre-line" }}>
                {renderAdditionalDescriptions()}
                <TooltipPrice price={price} />
            </Box>
        )
    }

    const tooltipContent = renderTooltipContent()

    return (
        <Tooltip title={tooltipContent} noDelay>
            <Icon size={`${additionalInfoIconSize}px`} name="info" sx={{ display: "block" }} />
        </Tooltip>
    )
}
