import { ArticleAction } from "@tm/models"
import { Icon } from "../../../generics/Icons"
import { IconButton } from "../../../generics/button"
import { Tooltip } from "../../../generics/tooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ImsIcon } from "../ImsIcon"

type IconActionProps = {
    action: ArticleAction
}

export function IconAction({ action: { tooltip, text, handler, icon, isHighlight } }: IconActionProps) {
    const imsIcon = useDefaultArticleItemState((x) => x.imsIcon)
    return (
        <Tooltip title={tooltip ?? text}>
            <IconButton onClick={handler}>
                {icon === "trader-icon" && imsIcon ? <ImsIcon /> : <Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            </IconButton>
        </Tooltip>
    )
}
