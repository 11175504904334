import { Box, BoxProps, styled } from "@mui/material"
import { ComponentType } from "react"

export const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "62px",
    height: "62px",
    zIndex: 2,
    "&[data-clickable]:hover": {
        borderColor: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
    img: {
        objectFit: "contain",
    },
})) as ComponentType<BoxProps>
