import { styled } from "@mui/material"
import { ElementType, HTMLAttributes } from "react"
import { plateCountryCodeMapper } from "@tm/utils"
import IconCh from "./icons/ch.svg"
import IconN from "./icons/n.svg"
import IconEu from "./icons/eu.svg"

export type LicensePlateProps = {
    value: string
    countryCode?: string
    showCountryCode?: boolean
    size?: "extrasmall" | "small" | "medium" | "large" | "extralarge"
}

function LicensePlateComponent(props: LicensePlateProps & HTMLAttributes<HTMLElement>) {
    const { value, countryCode, className, style } = props
    const showCountryCode = !countryCode ? false : props.countryCode === undefined ? true : props.countryCode

    const plateStyle = getPlateStyle(countryCode || "")
    const plateCountryCode = plateCountryCodeMapper(countryCode || "")

    return (
        <div className={className} style={style}>
            <div className="country">
                {showCountryCode && (
                    <>
                        <CountryIcon countryCode={countryCode} />
                        {plateStyle !== "CH" && <div className="country-code">{plateCountryCode}</div>}
                    </>
                )}
            </div>
            <div className="value">{value}</div>
        </div>
    )
}

function CountryIcon({ countryCode }: { countryCode?: string }) {
    const plateStyle = getPlateStyle(countryCode || "")
    switch (plateStyle) {
        case "CH":
            return <IconCh className="icon" />
        case "N":
            return <IconN className="icon" />
        default:
            return <IconEu className="icon" />
    }
}

const LicensePlate: ElementType<LicensePlateProps & HTMLAttributes<HTMLElement>> = styled(LicensePlateComponent)<LicensePlateProps>(
    ({ size, countryCode, theme }) => {
        let fontSize: string | number | undefined
        const plateStyle = getPlateStyle(countryCode || "")
        switch (size) {
            case "extrasmall":
                fontSize = "0.8em"
                break
            case "small":
                fontSize = "0.9em"
                break
            case "large":
                fontSize = "1.2em"
                break
            case "extralarge":
                fontSize = "1.5em"
                break
            case "medium":
            default:
                fontSize = "1em"
        }

        return {
            background: plateStyle === "NL" ? "#fdd504" : "#FBFBFB",
            border: "1px solid #a0a0a0",
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.text.primary,
            display: "inline-flex",
            fontSize,
            minWidth: "4em",
            lineHeight: 1,
            position: "relative",
            whiteSpace: "nowrap",
            height: "1.5em",
            verticalAlign: "text-bottom",
            flexShrink: 0,
            ".country": {
                color: theme.palette.common.white,
                textTransform: "uppercase",
                borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                display: "block",
                background: getPlateCountryBackground(plateStyle),
                width: "1em",
                minWidth: "1em",
                position: "relative",
            },
            ".country-code": {
                position: "absolute",
                fontSize: "0.4em",
                bottom: "0.25em",
                left: "50%",
                transform: "translateX(-50%)",
            },
            ".icon": {
                position: "absolute",
                height: plateStyle === "CH" ? "0.8em" : "0.7em",
                width: "0.7em",
                top: plateStyle === "CH" ? "0.35em" : "0.05em",
                left: "50%",
                transform: "translateX(-50%)",
            },
            ".value": {
                padding: "0 0.3em",
                alignSelf: "center",
                fontFamily: "EuroPlate",
                letterSpacing: "normal",
            },
        }
    }
)

export { LicensePlate }

function getPlateStyle(countryCode?: string): "EU" | "CH" | "N" | "NL" {
    switch (countryCode?.toLowerCase()) {
        case "no":
        case "n":
            return "N"
        case "ch":
            return "CH"
        case "nl":
            return "NL"
        default:
            return "EU"
    }
}

function getPlateCountryBackground(plateStyle: ReturnType<typeof getPlateStyle>) {
    switch (plateStyle) {
        case "CH": {
            return "#FBFBFB"
        }
        default:
            return "#003bb2"
    }
}
