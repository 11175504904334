import * as React from "react"
import { styled, Box, BoxProps } from "@mui/material"

type TabProps = BoxProps & { isSeleted?: boolean }

const Tab = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSeleted",
})<TabProps>(({ isSeleted, theme }) => ({
    ...(isSeleted && { transform: "translateY(3px)" }),
    height: "58px",
    width: "233px",
    backgroundColor: "white",
    borderRadius: "3px 0 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `2px solid ${isSeleted ? theme.colors?.highlight : theme.colors?.primary}`,
    position: "relative",
}))

const CenterContent = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
}))

type InfoWrapperProps = BoxProps & { shiftLeft?: boolean }

const InfoWrapper = styled(Box)<InfoWrapperProps>(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginLeft: "73px",
}))

const CarInfo = styled("div")(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: "11px",
    maxWidth: "155px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "5px",
}))

const CustomerInfo = styled("div")(({ theme }) => ({
    color: theme.palette.common.black,
    maxWidth: "155px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}))

const HalfImg = styled("img")(() => ({
    objectFit: "contain",
    position: "absolute",
    width: "100px",
    height: "40px",
    left: 0,
    clipPath: "inset(0 0 0 50%)",
    transform: "translateX(-50%)",
}))

export type Props = {
    isSelected?: boolean
    title?: string
    subtitle?: string
    img?: string
    emptyText: string
}

export const TabWithImage: React.FC<Props> = (props: Props) => {
    const { isSelected, title, subtitle, img, emptyText } = props
    return (
        <Tab isSeleted={isSelected}>
            {img && <HalfImg src={img} />}
            {!title && !subtitle && (
                <CenterContent>
                    <span>{emptyText}</span>
                </CenterContent>
            )}
            {(title || subtitle) && (
                <InfoWrapper shiftLeft={!!img}>
                    <CustomerInfo>{title}</CustomerInfo>
                    <CarInfo>{subtitle}</CarInfo>
                </InfoWrapper>
            )}
        </Tab>
    )
}
