import { Box, Skeleton, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { TreeNode as TreeNodeModel } from "@tm/models"
import { useMemo } from "react"
import { Bestseller } from "./Bestseller"
import { TreeNode } from "./TreeNode"

const Grid = styled("div", { shouldForwardProp: (prop) => prop !== "rows" })<{ rows: number }>(({ rows }) => ({
    display: "grid",
    gridTemplateRows: `repeat(${rows}, minmax(25px, 30px))`,
    gridAutoFlow: "column",
    columnGap: "1em",
    rowGap: "0.25em",
    height: "100%",
    padding: "1em 1em 1.5em 1em",
}))

// last-row
const BestsellerContainer = styled("div")({
    gridRowStart: 1,
    height: "100%",
    width: "100%",
    alignSelf: "stretch",
})

type Props = {
    bestseller?: boolean
    selectedNode?: TreeNodeModel
    onNodeClick: (node: TreeNodeModel, fastClick?: boolean) => void
    isLoading?: boolean
    applyProductGroups?: (productGroupIds: number[]) => void
    fastCalculation?: (productGroupIds: number[]) => void
    hideFastCalcButton?: boolean
    maxSelectableProductGroups?: number
    variant: "default" | "small"
}

export function ListView(props: Props) {
    const { bestseller, selectedNode, isLoading } = props
    const { translateText } = useLocalization()
    const isSmallVariant = props.variant === "small"
    const maxElements = isSmallVariant ? 12 : 30
    const maxCollumns = isSmallVariant ? 2 : 3
    const skeletons = useMemo(() => {
        return Array.from(Array(maxElements).keys()).map((_, index) => {
            return (
                <Box key={`SKel_${index}`} flexDirection="row" display="flex">
                    <Skeleton sx={{ mr: "6px" }} variant="circular" height={30} width={30} />
                    <Skeleton height={25} width={Math.floor(Math.random() * (250 - 151) + (isSmallVariant ? 50 : 150))} />
                </Box>
            )
        })
    }, [maxElements, isSmallVariant])

    const numberOfChildNodes = selectedNode?.childNodes?.length
    let gridTemplateColumns = numberOfChildNodes && numberOfChildNodes < 20 ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"
    if (isSmallVariant) {
        gridTemplateColumns = "1fr 1fr"
    }
    const gridColumnStart = numberOfChildNodes && numberOfChildNodes < 20 ? "3" : "4"

    // at some cases, we have more then 30 items so we need more then 10 rows to display this correct
    let gridRows = isSmallVariant ? 6 : 10

    if (numberOfChildNodes && numberOfChildNodes >= maxElements) {
        const maxRows = Math.round(numberOfChildNodes / maxCollumns)
        gridRows = maxRows + 1
    }

    if (isLoading) {
        return (
            <Grid rows={gridRows} sx={{ gridTemplateColumns }}>
                {skeletons.map((skel) => {
                    return skel
                })}
            </Grid>
        )
    }

    if (selectedNode?.hasChildNodes) {
        return (
            <Box>
                <Grid rows={gridRows} sx={{ gridTemplateColumns }}>
                    {!isSmallVariant && bestseller && props.selectedNode?.topProductGroups && props.selectedNode.hasChildNodes && (
                        <BestsellerContainer sx={{ gridColumnStart }}>
                            <Bestseller
                                Bestseller={props.selectedNode?.topProductGroups}
                                onNodeClick={props.onNodeClick}
                                applyProductGroups={props.applyProductGroups}
                                fastCalculation={props.fastCalculation}
                                hideFastCalcButton={props.hideFastCalcButton}
                                maxSelectableProductGroups={props.maxSelectableProductGroups}
                            />
                        </BestsellerContainer>
                    )}
                    {props.selectedNode?.childNodes &&
                        props.selectedNode.childNodes.length > 0 &&
                        props.selectedNode?.childNodes.map((node) => {
                            return <TreeNode key={`listViewNode${node.id}`} node={node} onClick={props.onNodeClick} hideIcon />
                        })}
                </Grid>
            </Box>
        )
    }

    return <Box>{translateText(12775)}</Box>
}
