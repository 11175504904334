import { Tab, TabProps } from "@mui/material"

export type LinkTabProps = TabProps & {
    component?: unknown
    href?: string
    key?: string
    label?: string
    to?: string
    value: unknown
    target?: string
}

export function LinkTab(props: LinkTabProps) {
    return <Tab component={props.component} {...props} />
}
