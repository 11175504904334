import { Box, FormControl, styled, Typography } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { SearchTreeProductGroup, TreeNode as TreeNodeModel } from "@tm/models"
import { ChangeEvent, useState } from "react"
import { getUIA } from "@tm/utils"
import { Button } from "../../generics/button"
import { Checkbox } from "../../generics/Checkbox"
import { FormControlLabel } from "../../generics/FormControlLabel"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"

const Wrapper = styled("div")(({ theme }) => ({
    width: "100%",
    padding: "0 1em 0 2em",
    borderLeft: `solid 1px ${theme.palette.highlight.main}`,
}))

type Props = {
    Bestseller?: SearchTreeProductGroup[]
    onNodeClick: (node: TreeNodeModel) => void
    applyProductGroups?: (productGroupIds: number[]) => void
    fastCalculation?: (productGroupIds: number[]) => void
    hideFastCalcButton?: boolean
    maxSelectableProductGroups?: number
}

export function Bestseller(props: Props) {
    const { translateText } = useLocalization()
    const [state, setState] = useState<number[]>([])
    const [length, setLength] = useState<number>(0)

    const maxItems = props?.maxSelectableProductGroups || 100
    const isDisabledBySlectedItems = state.length > maxItems

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const tempState = [...state]
        const id = parseInt(event.target.value)
        if (event.target.checked) {
            tempState.push(id)
        } else {
            tempState.remove((pId) => pId === id)
        }
        setState(tempState)
        setLength(tempState.length)
    }

    const applyProductGroupFilter = () => {
        if (props.applyProductGroups) {
            props.applyProductGroups(state)
        }
    }

    const onFastclickClick = () => {
        if (props.fastCalculation) {
            props.fastCalculation(state)
        }
    }

    return (
        <Wrapper>
            <Box sx={{ display: "flex", marginBottom: "15px" }}>
                <Icon name="category-bestseller" width={30} height={30} sx={{ color: (theme) => theme.colors?.highlight }} />
                <Typography variant="h3" pr="4px">
                    {translateText(158)}
                </Typography>
                <Typography variant="h3" color="highlight.main">
                    {translateText(1005)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", pl: "13px", pb: "15px", overflowY: "auto" }}>
                <FormControl component="fieldset" variant="standard" {...getUIA("SearchtreeV2BestsellerContainer")}>
                    {props.Bestseller?.map((node) => {
                        return (
                            <FormControlLabel
                                key={`checkLabel:${node.id}`}
                                control={
                                    <Checkbox
                                        key={`key_${node.id}`}
                                        disableRipple
                                        size="small"
                                        onChange={handleChange}
                                        name={node.name}
                                        value={node.id}
                                        {...getUIA("SearchtreeV2BestsellerCheckbox", node.id)}
                                    />
                                }
                                label={node.name}
                                {...getUIA("SearchtreeV2BestsellerLabel")}
                            />
                        )
                    })}
                </FormControl>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mt: "15px" }}>
                <Button
                    disabled={length === 0}
                    sx={{ mr: 2 }}
                    startIcon={<Icon name="check" />}
                    onClick={applyProductGroupFilter}
                    {...getUIA("SearchtreeV2BestsellerApply")}
                >
                    {translateText(22)}
                </Button>
                {!props.hideFastCalcButton && (
                    <Tooltip
                        title={isDisabledBySlectedItems ? translateText(13025).replace("{0}", maxItems.toString()) : translateText(1042)}
                        enterDelay={isDisabledBySlectedItems ? 0 : 1000}
                    >
                        <div>
                            <Button
                                disabled={length === 0 || isDisabledBySlectedItems}
                                color="highlight"
                                startIcon={<Icon name="fast-calculator" />}
                                onClick={onFastclickClick}
                                {...getUIA("SearchtreeV2BestsellerCalculate")}
                            >
                                {translateText(13000)}
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </Box>
        </Wrapper>
    )
}
