import { DispatchCosts, DispatchConditions, IErpRequest } from "..";
import { Item, ItemTour, TitleType, WarehouseDispatchType } from "../..";

export * from "./selection"
export * from "./warehouses"

export type OrderOptionsInternalRequest = IErpRequest & {
    workTaskId: string
    warehouseId: string | undefined
    favoriteOrderOptions?: FavoriteOrderOptions
}

export type ShowOptionsByWorktaskRequest = IErpRequest & {
    workTaskIds: Array<string>
    warehouseIds: Array<string>
    favoriteOrderOptions?: FavoriteOrderOptions
}

export type ShowOptionsByWorkTaskResponse = {
    distributorId?: number
    optionsByWorkTasks?: Array<OrderOptionsByWorkTask>
    errorText: string
    hasErrors: boolean
}

type OrderOptionsRespone = {
    distributorId?: number
    options: OrderOptions
    warehouseOptions: Array<OrderOptions>
    useOrderByWarehouse: boolean
    wholesalerOrderConfirmation?: boolean
    addWorkTaskInfoToOrderItemMemo?: boolean
    hasErrors: boolean
    errorText: string
} 

export type ShowDefaultOptionsRequest = IErpRequest

export type ShowDefaultOptionsResponse = OrderOptionsRespone

export type ShowGlobalOptionsRequest = IErpRequest & {
    favoriteOrderOptions?: FavoriteOrderOptions
    selectedShipmentModeId?: string
}

export type ShowGlobalOptionsResponse = OrderOptionsRespone

export type OrderOptionsByWorkTask = {
    workTaskId: string
    generalOptions?: OrderOptions
    warehouseOptions?: Array<OrderOptions>
    // hasAdditionalCosts?: boolean         // TODO Basket V2 Not used at all, should be removed in the service
}

export type OrderOptionsBufferdResponse =  OrderOptions | "reject"

export type OrderOptions = {
    selectedOptionsItem: Item
    warehouseId?: string
    deliveryAddresses?: AddressSelection
    billingAddresses?: AddressSelection
    paymentOptions?: PaymentOptionSelection
    shipmentModes?: ShipmentModeSelection
    deliveryDistribution?: DeliveryDistributionSelection
    billingModes?: BillingModeSelection
    orderProcessingOptions?: OrderProcessingOptionSelection
    backorderOptions?: BackorderOptionSelection
    tours?: TourSelection
    deliveryDate?: Date
    deliveryDateUpdateOnChange: boolean
    customerOrderNumber: string
    orderComment?: string
    hasCustomerOrderNumber: boolean
    customerOrderNumberMaxLength?: number
    hasOrderComment: boolean
    orderCommentMaxLength?: number
    hasItemComment: boolean
    itemCommentMaxLength?: number
}

export type SendOrderOptions = {
    warehouseId?: string
    billingAddress?: AddressSelectionItem
    deliveryAddress?: AddressSelectionItem
    billingMode?: BillingMode
    paymentOption?: PaymentOption
    shipmentMode?: ShipmentMode
    deliveryDistributionMode?: DeliveryDistributionMode
    tour?: TourOrderOption 
    orderProcessingOption?: OrderProcessingOption
    backorderOption?: BackorderOption
    deliveryDate?: Date
    customerOrderNumber?: string
    orderComment?: string
    hasCustomerOrderNumber?: boolean
    customerOrderNumberMaxLength?: number
    hasOrderComment?: boolean
    orderCommentMaxLength?: number
    hasItemComment?: boolean
    itemCommentMaxLength?: number
    wholesalerOrderConfirmation?: boolean
    orderConfirmationEmail?: string
}

export type OptionSelection = {
    id?: string
    description?: string
    selectionType?: SelectionType
    updateOrderOptionsOnChange?: boolean
    updateErpInformationOnChange?: boolean
    updateOrderOnChange?: boolean
    isNotVisible?: boolean
}

export type OptionSelectionItem = {
    id: string
    description: string
    isSelected: boolean
}

export type AddressSelection = OptionSelection & {    
    addresses: Array<AddressSelectionItem>
}

export type AddressSelectionItem = OptionSelectionItem & {
    addressAddition?: string
    title?: string
    titleType?: TitleType
    firstName?: string
    lastName?: string
    street?: string
    city?: string
    zip?: string
    country?: string
    companyName?: string
    eMail?: string
    phone?: string
    isEditable: boolean
    hasAdditionalCosts?: boolean
}

export type PaymentOptionSelection = OptionSelection & {
    paymentOptions: Array<PaymentOption>
}

type PaymentOption = OptionSelectionItem

export type ShipmentModeSelection = OptionSelection & {    
    shipmentModes: Array<ShipmentMode>
}

export type ShipmentMode = OptionSelectionItem & {
    costs?: DispatchCosts
    dispatchConditions?: DispatchConditions
    dispatchType?: WarehouseDispatchType
    tours?: TourSelection
    tour?: TourOrderOption //Supported in ErpInformationRequest TODO: check if it can be removed
}

export type DeliveryDistributionSelection = OptionSelection & {  
    deliveryDistributionModes: Array<DeliveryDistributionMode>
}

type DeliveryDistributionMode = OptionSelectionItem

export type BillingModeSelection = OptionSelection & {   
    billingModes: Array<BillingMode>
}

type BillingMode = OptionSelectionItem

export enum SelectionType {
    singleSelection = 0,
    multiSelection = 1
}

export type TourSelection = OptionSelection & {
    tours: Array<TourOrderOption>
}

export type TourOrderOption = OptionSelectionItem & Omit<ItemTour, "id" | "description">


export type OrderProcessingOptionSelection = OptionSelection & {
    orderProcessingOptions: Array<OrderProcessingOption>
}

type OrderProcessingOption = OptionSelectionItem

export type BackorderOptionSelection = OptionSelection & {
    backorderOptions: Array<BackorderOption>
}

type BackorderOption = OptionSelectionItem

export type FavoriteOrderOptions = {
    shipmentMode?: SelectedOption
    paymentMode?: SelectedOption
}

export type SelectedOption = {
    id: string
    description?: string
}

export type SelectOrderOptionsResponse = {
    selectedOptionsItem: Item
}