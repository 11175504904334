import { ChangeEvent, KeyboardEvent, ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState, MouseEvent } from "react"
import { TextField, TextFieldProps, styled, filledInputClasses, colors, ButtonProps } from "@mui/material"
import { alpha } from "@mui/system"
import { IconButton, SearchButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { Loader } from "../../generics/loader"

const SearchTextField = styled(TextField)<{ alwaysColordBorder?: boolean }>(({ theme, alwaysColordBorder }) => ({
    [`.${filledInputClasses.root}`]: {
        padding: 0,
        border: `1px solid ${
            theme.overwrites?.components?.textfield?.border?.color || alwaysColordBorder ? theme.palette.highlight.main : "#e2e2e1"
        }`,
        borderRadius: theme.radius?.default || "3px",
        backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:before": {
            content: "none",
        },
        "&:after": {
            content: "none",
        },

        "&:hover": {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        },
        [`&.${filledInputClasses.focused}`]: {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            borderColor: theme.palette.primary.main,
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        },
        [`&.${filledInputClasses.error}`]: {
            borderColor: theme.palette.error.main,
            borderBottom: `4px solid ${theme.palette.error.main}`,
            marginBottom: "0px",
            [`&.${filledInputClasses.focused}`]: {
                boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
            },
        },
    },
    [`.${filledInputClasses.input}`]: {
        padding: 8,
        boxSizing: "content-box",
        fontSize: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        lineHeight: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        height: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
    },
}))

const InlineSearchButton = styled(SearchButton)(({ theme }) => ({
    borderRadius: `${theme.radius?.default || "2px"} !important`,
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
}))

export type SearchFieldProps = Omit<TextFieldProps, "value" | "onChange" | "color"> & {
    value: string
    isLoading?: boolean
    onChange(value: string): void
    onStartSearch(): void
    alwaysColordBorder?: boolean
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "highlight"
}

function SearchFieldComponent(props: SearchFieldProps, ref: ForwardedRef<HTMLDivElement>) {
    const inputRef = useRef<HTMLInputElement>()

    const handleClickClear = useCallback(() => {
        props.onChange("")
        inputRef.current?.focus()
    }, [props])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.value)
        },
        [props]
    )

    const handleKeyStroke = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                props.onStartSearch()
            }
            props.onKeyUp?.(e)
        },
        [props]
    )

    const handleClickSearch = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            props.onStartSearch()
            e.stopPropagation()
        },
        [props]
    )
    const { onStartSearch, isLoading, color, ...rest } = props
    return (
        <SearchTextField
            autoComplete="off"
            {...rest}
            onChange={handleChange}
            onKeyUp={handleKeyStroke}
            ref={ref}
            variant="filled"
            inputProps={{
                ref: inputRef,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                ...rest.InputProps,
                endAdornment: (
                    <>
                        {isLoading && <Loader size="small" />}
                        {!!props.value && (
                            <IconButton size="small" onClick={handleClickClear}>
                                <Icon name="close" />
                            </IconButton>
                        )}
                        <InlineSearchButton color={color} onClick={handleClickSearch} />
                    </>
                ),
            }}
        />
    )
}

export const SearchField = forwardRef(SearchFieldComponent)
